import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from '../axios';
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";


const Registration = () => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    f_name:"", 
    s_name:"", 
    l_name:"", 
    email:"",
    mobile:"", 
    address:"", 
    city:"",
    state:"",
    country:"", 
    zip:"", 
    gender:null,
    education_institute:null, 
    education_degree:null,
    major:null, 
    degree_duration:null, 
    company_name:null, 
    company_designation:null,
    company_location:null,
    start_date:null,
    end_date:null, 
    salary:null,
    
  })
  const [userId, setUserId] = useState(null); // Define userId state

  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  // const [currentStudent, setCurrentStudent] = useState(false);
  // const [currentEmployee, setCurrentEmployee] = useState(false);

  // const handlecurrentStudent = (e) => {
  //   setCurrentStudent(e.target.checked);
  // };
  // const handlecurrentEmployee = (e) => {
  //   setCurrentEmployee(e.target.checked);
  // };

  useEffect(() => {
    const userIdFromStorage = localStorage.getItem('userId');
    setUserId(userIdFromStorage); // Set userId state
    console.log(userIdFromStorage);
  }, []);

  const handleSubmitData = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`http://localhost:8081/user/registeruser/${userId}`, formData);
      console.log(response.data);
      localStorage.clear();
      console.log("Before navigation");
      toast.success("registration Successful!")     
      navigate("/login");
      // alert("Registration successful!");
      console.log("After navigation");
    } catch (error) {
      // alert("Registration failed");
      toast.error(`registration Unsuccessfull! ${error.message}`)
    }
  }
  





  return (
    <div className="w-full h-max flex justify-center items-center flex-col">
      <Header />

      <div className="w-full md:w-2/3 shadow-sm  border-2 bg-white  mt-24 mb-4 rounded-md">
        <p className="text-center text-2xl mt-4 underline font-extrabold font-sarif">Complete Registration</p>
        <form onSubmit={handleSubmitData} method="" className=" w-full h-max mb-8  flex justify-center items-center flex-wrap gap-6 mt-2">
        <div className="w-full ml-14 mr-14 ">
         <div class="mt-10 flex items-center text-xl font-bold gap-1 after:h-px after:flex-1 after:bg-gray-300  after:content-['']">Information</div>
          </div>
          <div className="w-96">
            <label htmlFor="first-name" className="block text-xl font-medium leading-6 text-gray-900">First Name</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="f_name" id="f_name" autoComplete="given-name" className="required block w-full rounded-md border-2 border-2lack py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:placeholder:hidden focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="m -name" className="block text-xl font-medium leading-6 text-gray-900">Middle Name</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="s_name" id="s_name" autoComplete="given-name" className=" block w-full rounded-md border-2 py-1.5 pl-4  text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " />
            </div>
          </div>
          <div className="w-96">
            <label htmlFor="l-name" className="block text-xl font-medium leading-6 text-gray-900">Last Name</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="l_name" id="l_name" autoComplete="given-name" className=" block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " />
            </div>
          </div>
         

          

          <div className="w-96">
            <label htmlFor="mobile_no" className="block text-xl font-medium leading-6 text-gray-900">Mobile No</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="number" name="mobile" id="mobile" autoComplete="given-name" placeholder="7894561235" pattern="[0-9]{10}" className=" active:placeholder:text-white scroll-hidden block w-full pl-4 rounded-md border-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-black  focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-96">
            <label htmlFor="address" className="block text-xl font-medium leading-6 text-gray-900">address</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="address" placeholder="address" id="address" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black focus:placeholder:text-white :text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-96">
            <label htmlFor="country" className="block text-xl font-medium leading-6 text-gray-900">Country/residency</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="country" placeholder="india" id="country" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-96">
            <label htmlFor="city" className="block text-xl font-medium leading-6 text-gray-900">city</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="city" placeholder="city" id="city" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-96 ">
            <label htmlFor="state" className="block text-xl font-medium leading-6 text-gray-900">State</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="state" placeholder="Himachal pradesh" id="state" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-96">
            <label htmlFor="zipcode" className="block text-xl font-medium leading-6 text-gray-900">zip code</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="number" name="zip" placeholder="147303" id="zip" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>


           {/* <div className="w-full ml-14 mr-14 ">
         <div class="mt-10 flex items-center text-xl font-bold gap-1 after:h-px after:flex-1 after:bg-gray-300  after:content-['']">Work Details</div>
          </div> */}

          {/* <div className="w-96">
            <label htmlFor="company-name" className="block text-xl font-medium leading-6 text-gray-900">Company Name</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="company_name" placeholder="Company Name" id="email" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="designation" className="block text-xl font-medium leading-6 text-gray-900">Designation</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="designation" placeholder="eg. software developer" id="email" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="salary" className="block text-xl font-medium leading-6 text-gray-900">Salary</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="salary" placeholder="₹ 7,00,000.00" id="email" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="company-name" className="block text-xl font-medium leading-6 text-gray-900">Company Address</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="designation" placeholder="eg. software developer" id="email" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="notice-period" className="block text-xl font-medium leading-6 text-gray-900">
              Notice period
            </label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="notice_period" id="notice_period" placeholder="eg: 15 days" className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>


          <div className="w-96 flex items-center">
            <input onChange={handleFormData} type="checkbox" name="current_student" id="current_student" className="mr-2" onChange={handlecurrentEmployee} />
            <label htmlFor="current_student" className="block text-xl font-medium leading-6 text-gray-900">
              Current Employee
            </label>
          </div>

          <div className="w-96">
            <label htmlFor="start_date" className="block text-xl font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <div className="mt-2">
              <input onChange={handleFormData} type="date" name="start_date" id="start_date" placeholder="select date" className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="end_date" className="block text-xl font-medium leading-6 text-gray-900">
              End Date
            </label>
            <div className="mt-2">
              <input onChange={handleFormData} type="date" name="end_date" id="end_date" placeholder="select date" className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled={currentEmployee} />
            </div>
          </div>


          {/* education--------------------------- */}
{/* 
          <div className="w-full ml-14 mr-14 ">
         <div class="mt-10 flex items-center text-xl font-bold gap-1 after:h-px after:flex-1 after:bg-gray-300  after:content-['']">Education</div>
          </div>
          


          <div className="w-96">
            <label htmlFor="institute" className="block text-xl font-medium leading-6 text-gray-900">Institute Name</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="institute_name" placeholder="institute name" id="institute" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="degree" className="block text-xl font-medium leading-6 text-gray-900">Degree</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="degree" placeholder="eg:undergreduate" id="institute" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="specialization" className="block text-xl font-medium leading-6 text-gray-900">Specialization</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="specialization" placeholder="eg: computer science" id="specialization" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96 flex items-center">
            <input onChange={handleFormData} type="checkbox" name="current_student" id="current_student" className="mr-2" onChange={handlecurrentStudent} />
            <label htmlFor="current_student" className="block text-xl font-medium leading-6 text-gray-900">
              Current Student
            </label>
          </div>

          <div className="w-96">
            <label htmlFor="start_date" className="block text-xl font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <div className="mt-2">
              <input onChange={handleFormData} type="date" name="start_date" id="start_date" placeholder="select date" className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-96">
            <label htmlFor="end_date" className="block text-xl font-medium leading-6 text-gray-900">
              End Date
            </label>
            <div className="mt-2">
              <input onChange={handleFormData} type="date" name="end_date" id="end_date" placeholder="select date" className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled={currentStudent} />
            </div>
          </div> */}




{/* 
          <div className="w-full ml-14 mr-14">
            <label for="resume" class="mb-1 block text-xl font-medium">Upload Resume <span className="text-red-500">*</span></label>
            <input onChange={handleFormData} id="resume" name="resume" type="file" accept=".pdf" class=" ml-12 block w-96 text-sm file:mr-4 file:rounded-md file:border-0 file:bg-primary-500 file:py-2.5 file:px-4 file:text-sm file:font-semibold bg-white file:bg-blue-500 file:text-white hover:file:bg-blue-700 hover:cursor-pointer focus:outline-none  disabled:opacity-60 shadow-md border rounded-md" />
            <p className="ml-12 text-xs">Only PDF format allowed*</p>
          </div> */}



          {/* <div className="w-full ml-14 mr-14 ">
         <div class="mt-10 flex items-center text-xl font-bold gap-1 after:h-px after:flex-1 after:bg-gray-300  after:content-['']">Social Links</div>
          </div>
          <div className="w-96">
            <label htmlFor="linkedin" className="block text-xl font-medium leading-6 text-gray-900">LinkedIn</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="linkedin" placeholder="linkedin/linkedin4093" id="linkedin" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-96">
            <label htmlFor="x" className="block text-xl font-medium leading-6 text-gray-900">X</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="x_link" placeholder="x/linkedin4093" id="linkedin" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-96">
            <label htmlFor="instagram" className="block text-xl font-medium leading-6 text-gray-900">Instagram</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="instagram" placeholder="/linkedin4093" id="linkedin" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-96">
            <label htmlFor="leetcode" className="block text-xl font-medium leading-6 text-gray-900">LeetCode</label>
            <div className="mt-2">
              <input onChange={handleFormData} type="text" name="leetcode" placeholder="leetcode/linkedin4093" id="leetcode" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black   focus:placeholder:text-white focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div> */}


          <div className="w-full flex justify-center ">
            <button type="submit" className="text-xl w-40 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-md" >Register</button>
          </div>

        </form>


      </div>
      <Footer />
    </div>
  );
};

export default Registration;


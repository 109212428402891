import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import axios from '../axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {Link} from 'react-router-dom';
import Profilee from '../images/Profilee.png';

const AddedJobs = () => {
    const [jobs, setJobs] = useState([]);

    useEffect(() => {
        const fetchAllJobs = async () => {
            try {
                const response = await axios.get('/jobs/getalljobs');
                setJobs(response.data);
            } catch (error) {
                console.error('Error fetching all jobs:', error);
            }
        };

        fetchAllJobs();
    }, []);

    return (
        <div className="w-full flex flex-col">
            <Header />
            <section className="section-5 bg-2 pt-24">
                <div className="container py-5">
                    <div className="row ">
                        {/* Main Content */}
                        <div className="col-lg-12">
                            <div className="card border shadow mb-4 p-3">
                                <div className="card-body card-form">
                                    <div className="flex justify-between">
                                        <div>
                                            <h3 className="text-lg font-semibold mb-1">All Jobs</h3>
                                        </div>
                                        <div>
                                            <Link to="/myjobs" className="text-lg font-semibold mb-1">Go back</Link>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="bg-gray-100">
                                                <tr className="text-xl font-bold">
                                                    <th scope="col">S.No.</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Company</th>
                                                    <th scope="col">Job Created</th>
                                                    <th scope="col">Applicants</th>
                                                    <th scope="col">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {jobs.map((job, index) => (
                                                    <tr key={job.id} className="bg-gray-50">
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            <div className="font-semibold">{job.title}</div>
                                                            <div className="text-sm">{job.category} . {job.job_nature}</div>
                                                        </td>
                                                        <td>
                                                            <div className="font-semibold">{job.company_name}</div>
                                                            <div className="text-sm">{job.location} </div>
                                                        </td>
                                                        {/* <td>{new Date(job.createdAt).toLocaleDateString()}</td>
                                                        <td>{job.applications} Applications</td>
                                                        <td>
                                                            <div className="text-capitalize">{job.status}</div>
                                                        </td> */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default AddedJobs;

import React from "react";
import job360 from "../images/job360.png";
import linkedinIcon from "../images/linkedinIcon.png";
import instagramIcon from "../images/instagramIcon.png";
import twitterIcon from "../images/twitterIcon.png";
import facebookIcon from "../images/facebookIcon.png";
import Dhe from "../images/sarvatraFamily/Dhe.png";
import holistic_harbour from '../images/sarvatraFamily/holistic_harbour.jpeg';
import vb_itr from "../images/sarvatraFamily/vb_itr.png";
import poojawala from "../images/sarvatraFamily/poojawala.png";
import sarvatr from "../images/sarvatraFamily/sarvatr.png";
import Tudu from "../images/sarvatraFamily/Tudu.png";
import Tredul from "../images/sarvatraFamily/Tredul.png";
import swadeshi from "../images/sarvatraFamily/swadeshi.png";
import vikshit_india from "../images/sarvatraFamily/vikshit_india.png";
import Shiskha_mahakhumbh from "../images/sarvatraFamily/Shiskha_mahakhumbh.png";
import rase from "../images/sarvatraFamily/rase.png";




const Footer = () => {
  return (<>

    <div className="w-full flex flex-wrap justify-center lg:justify-evenly p-3 bg-zinc-300 ">
      <div className="w-72 m-2  bg-transparent  ">
        <img src={job360} alt="job360" className="w-40 h-30 mb-3" />
        <div className="bg-transparent">
          <p className=" text-black ml-2 font-bold underline ">Social Links</p>
        </div>
        <div className="flex flex-wrap gap-x-1 bg-transparent " >
          <a href="https://www.dhe.org.in/" target="blank" className="bg-transparent filter grayscale" >  <img src={linkedinIcon} className="w-12 h-12 bg-none " alt="dhe" /></a>
          <a href="https://www.dhe.org.in/" target="blank" className="bg-transparent filter grayscale" >  <img src={instagramIcon} className="w-12 h-12 bg-none" alt="dhe" /></a>
          <a href="https://www.dhe.org.in/" target="blank" className="bg-transparent filter grayscale" >  <img src={facebookIcon} className="w-12 h-12 bg-none" alt="dhe" /></a>
          <a href="https://www.dhe.org.in/" target="blank" className="bg-transparent filter grayscale" >  <img src={twitterIcon} className="w-12 h-12 bg-none" alt="dhe" /></a>

        </div>
      </div>

      <div className="w-72 m-2 bg-transparent ">
        <div className="text-center  bg-transparent">
          <p className="text-2xl font-bold font-sans underline text-black ">Usefull Links</p>
        </div >
        <div className="bg-transparent mb-4 w-full flex flex-wrap justify-center items-center gap-3  border-black">
          <a href="https://www.dhe.org.in/" target="blank" className="bg-transparent" >  <img src={Dhe} className="w-12 h-12 bg-none rounded-full " alt="dhe" /></a>
          <a href="https://www.rase.co.in/" target="blank" >  <img src={Shiskha_mahakhumbh} className="w-12 h-12 bg-transparent rounded-full" alt="shiksha" /> </a>
          <a href="https://www.tudu.co.in/" target="blank" >  <img src={Tudu} className="w-12 h-12 bg-transparent rounded-full" alt="tudu" /> </a>
          <a href="https://www.jobs360degree.com" target="blank" >  <img src={job360} className="w-12 h-12 bg-transparent rounded-full object-contain" alt="siksha" /></a>
          <a href="https://www.sarvatr.co.in/" target="blank" >  <img src={sarvatr} className="w-12 h-12 bg-transparent rounded=full" alt="sarvatra1" /> </a>
          <a href="https://poojawala.in/" target="blank" >  <img src={poojawala} className="w-12 h-12 bg-transparent rounded-full" alt="poojawala" /> </a>
          <a href="https://www.itrchandigarh.org/" target="blank" >  <img src={vb_itr} className="w-12 h-12 bg-transparent rounded-full" alt="itr" />  </a>
          <a href="https://www.alltemples.org.in/" target="blank" >  <img src={holistic_harbour} className="w-12 h-12 bg-transparent rounded-full" alt="harbal" /></a>
          <a href="https://www.tredul.in/" target="blank" >  <img src={Tredul} className="w-12 h-12 bg-transparent rounded-full" alt="tredul" /> </a>
          <a href="https://vidyabharti.net/" target="blank" >  <img src={rase} className="w-12 h-12 bg-transparent rounded-full" alt="vidyabharti" /> </a>
          <a href="https://vi.rase.co.in/" target="blank" >  <img src={vikshit_india} className="w-12 h-12 bg-transparent rounded-full" alt="viksitbharti" /></a>
          <a href="https://swadeshibazar.co.in/" target="blank" >  <img src={swadeshi} className="w-12 h-12 bg-transparent rounded-full" alt="siksha" /></a>
        </div>
      </div>

      <div className="w-[280px] m-2 text-center bg-transparent text-neutral-200">
        <div className="text-center  bg-transparent">
          <p className="text-2xl font-bold font-sans underline text-black">Services</p>
        </div >
        <a href="//" className="hover:text-orange-600" ><p>Jobs</p> </a>
        <a href="//" className="hover:text-orange-600" ><p>Build Resume</p> </a>
        <a href="//" className="hover:text-orange-600" ><p>Institutions</p> </a>
        <a href="//" className="hover:text-orange-600" ><p>Post Jobs</p> </a>
      </div>

      <div className="w-72 m-2 bg-transparent">
        <div className="text-center  bg-transparent">
          <p className="text-2xl font-bold font-sans underline text-black ">Address</p>
        </div >
        <div className="bg-transparent" >
          <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.6606253608606!2d76.70608500958036!3d30.699823374494194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef39a32ed3c1%3A0x9ff15a51ad5117e9!2sDepartment%20of%20Holistic%20Education!5e0!3m2!1sen!2sin!4v1707117984398!5m2!1sen!2sin"  loading="lazy" referrerPolicy="no-referrer-when-downgrade" className=" w-42 h-38 border-black border-solid border-2 rounded-lg "></iframe>
        </div>
      </div>
    </div>
    <div className="w-full h-[70px] flex justify-center items-center text-xl   bg-zinc-500  ">
      <p className="ml-16 md:ml-0 text-zinc-300 bg-transparent ">© 2024. All Rights Reserved <span className="ml-14 md:ml-0 text-center underline text-2xl text-white font-bold">Jobs360degree</span></p>
    </div>

  </>
  )
}
export default Footer;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import NavBar from './Navbar';
// import job360 from '../images/job360.png';
// import MenuBurgerIcon from './MenuBurgerIcon';

// const Header = ({ isLoggedIn }) => {
//     const [showNavBar, setShowNavBar] = useState(false);

//     const toggleNavbar = () => {
//         setShowNavBar(!showNavBar);
//     };

//     return (
//         <div>
//             <header className="w-full h-35 bg-gray-600 flex justify-between items-center pl-10 pr-10 fixed z-50 ">
//                 <div className="bg-transparent">
//                     <Link to="/" className="a">
//                         <img src={job360} className="w-[150px] h20 bg-none" alt="job" />
//                     </Link>
//                 </div>
//                 {/* Always display MenuBurgerIcon for small screens */}
               
//                 <nav className="hidden md:flex justify-between items-center  border-2 border-white   ">
//                     <NavBar isLoggedIn={isLoggedIn} />
//                 </nav>

//                 <nav className="w-6 border-2 border-white " onClick={toggleNavbar}>
//                     <MenuBurgerIcon />
//                 </nav>

//                 {showNavBar && (
//                     <nav className="w-50 h-68 border-2 mt-50  border-white mt-26 ">
//                         <NavBar isLoggedIn={isLoggedIn} />
//                     </nav>
//                 )}

//             </header>
//         </div>
//     );
// };

// export default Header;


import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from './Navbar';
import job360 from '../images/job360.png';
import MenuBurgerIcon from './MenuBurgerIcon';
import MenuCard from '../employee/menu';
import Notification from './Notification';




const Header = ({ isLoggedIn }) => {
    const [showNavBar, setShowNavBar] = useState(false);


    const toggleNavbar = () => {
        setShowNavBar(!showNavBar);
    };

    return (
        <div className="w-full bg-black ">
            <header  className="w-full h-34 bg-white flex justify-between items-center pl-10 pr-10 md:pr-20 md:pl-20  fixed z-50 top-0">
                <div className="w-auto h-auto bg-transparent">
                    <Link to="/" className="a">
                        <img src={job360} className="w-28 h-20 md:w-40 md:h-30 bg-none" alt="job" />
                    </Link>
                </div>

                {/* Always display MenuBurgerIcon for small screens */}
                <nav className="hidden  md:flex justify-between items-center mt-8 ">
                    <NavBar isLoggedIn={isLoggedIn} showNavBar={showNavBar} />
                </nav>

                {/* <nav className="w-6 border-2 border-white md:hidden" onClick={toggleNavbar}>
                    <MenuBurgerIcon /> 
                </nav> */}

                {isLoggedIn ? (
                    <nav className="flex  flex-wrap justify-between items-center gap-3">
                        <Notification />

                        <MenuCard isLoggedIn={isLoggedIn} showNavBar={showNavBar} />
                    </nav>
                ) : (
                    <nav className="w-6 border-2 border-white md:hidden" onClick={toggleNavbar}>
                        <MenuBurgerIcon /> 
                    </nav>
                )}


            </header>
            {showNavBar && (
                <nav className=" w-64 h-mx bg-gray-100 flex flex-wrap justify-center items-center fixed right-0 z-40 top-20 rounded-md">
                    <NavBar isLoggedIn={isLoggedIn} showNavBar={showNavBar} />
                </nav>
            )}
            {/* Add padding to the content below the fixed header */}
            <div className="pt-34 md:pt-0"> 
                {/* Content here */}
            </div>
        </div>
    );
}

export default Header;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBigL1eAkPyQgd6-Sx7AMSgDgjDOdGyLSw",
  authDomain: "job360degree-396bf.firebaseapp.com",
  projectId: "job360degree-396bf",
  storageBucket: "job360degree-396bf.appspot.com",
  messagingSenderId: "187237480279",
  appId: "1:187237480279:web:e138432d960fd9f909f012"
};

// Initialize Firebase


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);



// import { initializeApp } from "firebase/app";
// import {
//   GoogleAuthProvider,
//   getAuth,
//   signInWithPopup,
//   signInWithEmailAndPassword,
//   createUserWithEmailAndPassword,
//   sendPasswordResetEmail,
//   signOut,
// } from "firebase/auth";
// import {
//   getFirestore,
//   query,
//   getDocs,
//   collection,
//   where,
//   addDoc,
// } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyBigL1eAkPyQgd6-Sx7AMSgDgjDOdGyLSw",
//   authDomain: "job360degree-396bf.firebaseapp.com",
//   projectId: "job360degree-396bf",
//   storageBucket: "job360degree-396bf.appspot.com",
//   messagingSenderId: "187237480279",
//   appId: "1:187237480279:web:e138432d960fd9f909f012"
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const db = getFirestore(app);
// const googleProvider = new GoogleAuthProvider();

// const signInWithGoogle = async () => {
//   try {
//     const res = await signInWithPopup(auth, googleProvider);
//     const user = res.user;
//     const q = query(collection(db, "users"), where("uid", "==", user.uid));
//     const docs = await getDocs(q);
//     if (docs.docs.length === 0) {
//       await addDoc(collection(db, "users"), {
//         uid: user.uid,
//         name: user.displayName,
//         authProvider: "google",
//         email: user.email,
//       });
//     }
//     window.location.href='/SeekerProfile';
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

// const logInWithEmailAndPassword = async (email, password) => {
//   try {
//     await signInWithEmailAndPassword(auth, email, password);
//     console.log(email,password);
//   } catch (err) {
//     console.error(err);
//     alert("check email or password!");
//   }
// };

// const registerWithEmailAndPassword = async (name, email, password) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, "users"), {
//       uid: user.uid,
//       name,
//       authProvider: "local",
//       email,
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };


// const sendPasswordReset = async (email) => {
//   try {
//     await sendPasswordResetEmail(auth, email);
//     alert("Password reset link sent!");
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

// const logout = async (auth) => {
//    await signOut(auth);
// };


// export {
//   auth,
//   db,
//   signInWithGoogle,
//   logInWithEmailAndPassword,
//   registerWithEmailAndPassword,
//   sendPasswordReset,
//   logout,
// };
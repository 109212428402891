// import React, { useState, useEffect } from "react";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import "../components/assests/Home.css";
// import { auth } from "../Firebase";
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// import LocationOn from '@mui/icons-material/LocationOn';
// import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
// import Cards from "../components/cards";
// // import JobCategaries from "../components/JobCategaries";

// const Home = () => {
//   const [user, setUser] = useState(null);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((currentUser) => {
//       setUser(currentUser);
//     });
//     return () => {
//       unsubscribe();
//     };
//   }, []);

//   return (
//     <div className="overflow-hidden top-0">
//       <Header isLoggedIn={!!user} />

//       <div className="hero relative   bg-cover  bg-center  sm:h-screen "  >
//         <div class="container">
//           <div class="row">
//             <div class="col-12 col-xl-8">
//               <p className="text-white text-6xl font-bold ">Find your dream job</p>
//               <p className="text-white text-2xl font-bold">Thousands of jobs available.</p>
//               <div class="banner-btn mt-5  "><a href="#explore" class="bg-blue-500 text-white p-3  mb-4 rounded-lg mb-sm-0  font-medium">Explore Now</a></div>
//             </div>
//           </div>
//         </div>
//       </div>



//       <section class="section-1 py-5 " id="explore">
//         <div class="container">
//           <div class="card border-0 shadow p-5">
//             <div class="row">
//               <div class="col-md-3 mb-3 mb-sm-3 mb-lg-0">
//                 <input type="text" class="form-control" name="search" id="search" placeholder="Keywords" />
//               </div>
//               <div class="col-md-3 mb-3 mb-sm-3 mb-lg-0">
//                 <input type="text" class="form-control" name="search" id="search" placeholder="Location" />
//               </div>
//               <div class="col-md-3 mb-3 mb-sm-3 mb-lg-0">
//                 <select name="category" id="category" class="form-control">
//                   <option value="">Select a Category</option>
//                   <option value="">Engineering</option>
//                   <option value="">Accountant</option>
//                   <option value="">Information Technology</option>
//                   <option value="">Fashion designing</option>
//                 </select>
//               </div>

//               <div class=" col-md-3 mb-xs-3 mb-sm-3 mb-lg-0">
//                 <div class="d-grid gap-2">
//                   <a href="jobs.html" class="btn btn-primary btn-block">Search</a>
//                 </div>

//               </div>
//             </div>
//           </div>
//         </div>
//       </section>


//       {/* <div style={{ marginTop: 50 }}>
//         <Slider />
//       </div> */}


//       {/* <div className="w-full p-12 h-auto bg-gray-200 pb-6  flex justify-center items-center flex-wrap gap-8 mb-4">
//         <div className="w-11/12 h-max  text-4xl font-bold text-black  *:bg-transparent underline " >Jobs Categories</div>
//         <JobCategaries />
//       </div> */}
//       <section class="section-2 bg-2 py-5">
//         <div class="container">
//           <h2 className=" divide-y-2">Popular Categories</h2>
//           <hr class="border bg-black border-black w-32 h-2" />
//           <div class="row pt-5 gap-y-10">
//             <div class="col-lg-4 col-xl-3  col-md-6 ">
//               <div class="single_catagory ">
//                 <a href="jobs.html"><h4 class="pb-2">Design &amp; Creative</h4></a>
//                 <p class="mb-0"> <span>40</span> Available position</p>
//               </div>
//             </div>
//             <div class="col-lg-4 col-xl-3 col-md-6 ">
//               <div class="single_catagory ">
//                 <a href="jobs.html"><h4 class="pb-2">Finance</h4></a>
//                 <p class="mb-0"> <span>60</span> Available position</p>
//               </div>
//             </div>
//             <div class="col-lg-4 col-xl-3 col-md-6 ">
//               <div class="single_catagory">
//                 <a href="jobs.html"><h4 class="pb-2">Banking</h4></a>
//                 <p class="mb-0"> <span>70</span> Available position</p>
//               </div>
//             </div>
//             <div class="col-lg-4 col-xl-3 col-md-6 ">
//               <div class="single_catagory">
//                 <a href="jobs.html"><h4 class="pb-2">Data Science</h4></a>
//                 <p class="mb-0"> <span>80</span> Available position</p>
//               </div>
//             </div>
//             <div class="col-lg-4 col-xl-3 col-md-6 ">
//               <div class="single_catagory">
//                 <a href="jobs.html"><h4 class="pb-2">Marketing</h4></a>
//                 <p class="mb-0"> <span>70</span> Available position</p>
//               </div>
//             </div>
//             <div class="col-lg-4 col-xl-3 col-md-6 ">
//               <div class="single_catagory">
//                 <a href="jobs.html"><h4 class="pb-2">Data Analayst</h4></a>
//                 <p class="mb-0"> <span>40</span> Available position</p>
//               </div>
//             </div>
//             <div class="col-lg-4 col-xl-3 col-md-6 ">
//               <div class="single_catagory">
//                 <a href="jobs.html"><h4 class="pb-2">Sales Executive</h4></a>
//                 <p class="mb-0"> <span>80</span> Available position</p>
//               </div>
//             </div>
//             <div class="col-lg-4 col-xl-3 col-md-6 ">
//               <div class="single_catagory">
//                 <a href="jobs.html"><h4 class="pb-2">Digital Marketing</h4></a>
//                 <p class="mb-0"> <span>70</span> Available position</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
      
//       <section class="section-3 bg-white py-5">
//         <div class="container">
//           <h2 class="text-3xl font-bold">Latest Jobs</h2>
//           <hr class="border-2 border-black w-20 h-2 my-5" />

//           <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
//             <Cards />
//           </div>
//         </div>
//       </section>

//       <section class="section-3  py-5">
//         <div class="container">
//           <h2>Featured Jobs</h2>
//           <hr class="border bg-black border-black  w-20 h-2" />
//           <div class="row pt-5">
//             <div class="job_listing_area">
//               <div class="job_lists">
//                 <div class="row gap-y-6">
//                   <div class=" col-md-4 ">
//                     <div class="card">
//                       <div class="  card-body">
                     
//                         <h3 class=" border-0 fs-5 pb-2 mb-0">Web Designer</h3>
//                         <p>FIN Infocom</p>
//                         <p>Industry Type: Software Product & IT industry</p>
//                         <div class="bg-light p-3 border">
//                           <p class="mb-0">
//                             <span class="fw-bolder"><LocationOn className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">
//                               Hyderabad</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><BusinessOutlinedIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">On site</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><CurrencyRupeeIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">0-3 Lacs PA</span>
//                           </p>
//                         </div>

//                         <div class="d-grid mt-3">
//                           <a href="job-detail.html" class="btn btn-primary btn-lg">Details</a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                   <div class="col-md-4">
//                     <div class=" card ">
//                       <div class="card-body">
//                         <h3 class="border-0 fs-5 pb-2 mb-0">Wordpress Developer</h3>
//                         <p>PAY Seva Digital</p>
//                         <p>Industry Type: FMCG and digital product & IT industry</p>
//                         <div class="bg-light p-3 border">
//                           <p class="mb-0">
//                             <span class="fw-bolder"><LocationOn className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Noida</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><BusinessOutlinedIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Remote</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><CurrencyRupeeIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">2-3 Lacs PA</span>
//                           </p>
//                         </div>

//                         <div class="d-grid mt-3">
//                           <a href="job-detail.html" class="btn btn-primary btn-lg">Details</a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="col-md-4">
//                     <div class="card ">
//                       <div class="card-body">
//                         <h3 class="border-0 fs-5 pb-2 mb-0">UI UX Designer</h3>
//                         <p>Suntech Solutions</p>
//                         <p>Industry Type: IT Services & Consulting & IT industry</p>
//                         <div class="bg-light p-3 border">
//                           <p class="mb-0">
//                             <span class="fw-bolder"><LocationOn className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Mohali</span>
//                           </p>

//                           <p class="mb-0">
//                             <span class="fw-bolder"><BusinessOutlinedIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Remote</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><CurrencyRupeeIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">0-2 Lacs PA</span>
//                           </p>
//                         </div>

//                         <div class="d-grid mt-3">
//                           <a href="job-detail.html" class="btn btn-primary btn-lg">Details</a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="col-md-4">
//                     <div class="card ">
//                       <div class="card-body">
//                         <h3 class="border-0 fs-5 pb-2 mb-0">Mern Developer</h3>
//                         <p> Estrella Technologies</p>
//                         <p>Industry Type: IT Services & Consulting & IT industry</p>
//                         <div class="bg-light p-3 border">
//                           <p class="mb-0">
//                             <span class="fw-bolder"><LocationOn className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Gurugram</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><BusinessOutlinedIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">on-site</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><CurrencyRupeeIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">0-5 Lacs PA</span>
//                           </p>
//                         </div>

//                         <div class="d-grid mt-3">
//                           <a href="job-detail.html" class="btn btn-primary btn-lg">Details</a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="col-md-4">
//                     <div class="card ">
//                       <div class="card-body">
//                         <h3 class="border-0 fs-5 pb-2 mb-0">Software Developer</h3>
//                         <p>Bluelupin</p>
//                         <p>Industry Type: Software Product & IT industry</p>
//                         <div class="bg-light p-3 border">
//                           <p class="mb-0">
//                             <span class="fw-bolder"><LocationOn className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Pune</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><BusinessOutlinedIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Remote</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><CurrencyRupeeIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">0-5 Lacs PA</span>
//                           </p>
//                         </div>

//                         <div class="d-grid mt-3">
//                           <a href="job-detail.html" class="btn btn-primary btn-lg">Details</a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div class="col-md-4">
//                     <div class="card">
//                       <div class="card-body">
//                         <h3 class="border-0 fs-5 pb-2 mb-0">React Developer</h3>
//                         <p>Techlead Software</p>
//                         <p>Industry Type: IT Services & Consulting & IT industry</p>
//                         <div class="bg-light p-3 border">
//                           <p class="mb-0">
//                             <span class="fw-bolder"><LocationOn className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Delhi</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><BusinessOutlinedIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">Remote</span>
//                           </p>
//                           <p class="mb-0">
//                             <span class="fw-bolder"><CurrencyRupeeIcon className="h-4 w-10 ml-2 mr-2" /></span>
//                             <span class="ps-1">1-3 Lacs PA</span>
//                           </p>
//                         </div>

//                         <div class="d-grid mt-3">
//                           <a href="job-detail.html" class="btn btn-primary btn-lg">Details</a>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Footer />
//     </div>
//   );
// };
// export default Home;
import React, { useState, useEffect } from "react";
import axios from "../axios";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../components/assests/Home.css";
import { auth } from "../Firebase";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOn from '@mui/icons-material/LocationOn';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import Cards from "../components/cards";

const Home = () => {
  const [user, setUser] = useState(null);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Replace with your actual backend endpoint
    axios.get('/jobs/getalljobs')
      .then(response => setJobs(response.data))
      .catch(error => console.error('Error fetching jobs:', error));
  }, []);

  return (
    <div className="overflow-hidden top-0">
      <Header isLoggedIn={!!user} />

      <div className="hero relative bg-cover bg-center sm:h-screen">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-8">
              <p className="text-white text-6xl font-bold">Find your dream job</p>
              <p className="text-white text-2xl font-bold">Thousands of jobs available.</p>
              <div className="banner-btn mt-5">
                <a href="#explore" className="bg-blue-500 text-white p-3 mb-4 rounded-lg mb-sm-0 font-medium">Explore Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-1 py-5" id="explore">
        <div className="container">
          <div className="card border-0 shadow p-5">
            <div className="row">
              <div className="col-md-3 mb-3 mb-sm-3 mb-lg-0">
                <input type="text" className="form-control" name="search" id="search" placeholder="Keywords" />
              </div>
              <div className="col-md-3 mb-3 mb-sm-3 mb-lg-0">
                <input type="text" className="form-control" name="search" id="search" placeholder="Location" />
              </div>
              <div className="col-md-3 mb-3 mb-sm-3 mb-lg-0">
                <select name="category" id="category" className="form-control">
                  <option value="">Select a Category</option>
                  <option value="Engineering">Engineering</option>
                  <option value="Accountant">Accountant</option>
                  <option value="Information Technology">Information Technology</option>
                  <option value="Fashion designing">Fashion designing</option>
                </select>
              </div>
              <div className="col-md-3 mb-xs-3 mb-sm-3 mb-lg-0">
                <div className="d-grid gap-2">
                  <a href="jobs.html" className="btn btn-primary btn-block">Search</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-2 bg-2 py-5">
        <div className="container">
          <h2 className="divide-y-2">Popular Categories</h2>
          <hr className="border bg-black border-black w-32 h-2" />
          <div className="row pt-5 gap-y-10">
            {/* Add category details here */}
            <div class="col-lg-4 col-xl-3  col-md-6 ">
              <div class="single_catagory ">
                <a href="jobs.html"><h4 class="pb-2">Design &amp; Creative</h4></a>
                <p class="mb-0"> <span>40</span> Available position</p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6 ">
              <div class="single_catagory ">
                <a href="jobs.html"><h4 class="pb-2">Finance</h4></a>
                <p class="mb-0"> <span>60</span> Available position</p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6 ">
              <div class="single_catagory">
                <a href="jobs.html"><h4 class="pb-2">Banking</h4></a>
                <p class="mb-0"> <span>70</span> Available position</p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6 ">
              <div class="single_catagory">
                <a href="jobs.html"><h4 class="pb-2">Data Science</h4></a>
                <p class="mb-0"> <span>80</span> Available position</p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6 ">
              <div class="single_catagory">
                <a href="jobs.html"><h4 class="pb-2">Marketing</h4></a>
                <p class="mb-0"> <span>70</span> Available position</p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6 ">
              <div class="single_catagory">
                <a href="jobs.html"><h4 class="pb-2">Data Analayst</h4></a>
                <p class="mb-0"> <span>40</span> Available position</p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6 ">
              <div class="single_catagory">
                <a href="jobs.html"><h4 class="pb-2">Sales Executive</h4></a>
                <p class="mb-0"> <span>80</span> Available position</p>
              </div>
            </div>
            <div class="col-lg-4 col-xl-3 col-md-6 ">
              <div class="single_catagory">
                <a href="jobs.html"><h4 class="pb-2">Digital Marketing</h4></a>
                <p class="mb-0"> <span>70</span> Available position</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    {/* /latest job */}
      <section className="section-3 bg-white py-5">
        <div className="container">
          <h2 className="text-3xl font-bold">Latest Jobs</h2>
          <hr className="border-2 border-black w-20 h-2 my-5" />
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
            {jobs.map((job) => (
              <div key={job.id} className="card">
                <div className="card-body">
                  <h3 className="border-0 fs-5 pb-2 mb-0">{job.title}</h3>
                  <p>{job.company_name}</p>
                  <p>Industry Type: {job.category}</p>
                  <div className="bg-light p-3 border">
                    <p className="mb-0">
                      <span className="fw-bolder"><LocationOn className="h-4 w-10 ml-2 mr-2" /></span>
                      <span className="ps-1">{job.location}</span>
                    </p>
                    <p className="mb-0">
                      <span className="fw-bolder"><BusinessOutlinedIcon className="h-4 w-10 ml-2 mr-2" /></span>
                      <span className="ps-1">{job.job_nature}</span>
                    </p>
                    <p className="mb-0">
                      <span className="fw-bolder"><CurrencyRupeeIcon className="h-4 w-10 ml-2 mr-2" /></span>
                      <span className="ps-1">{job.salary}</span>
                    </p>
                  </div>
                  <div className="d-grid mt-3">
                    <a href={`jobdetails/${job.id}`} className="btn btn-primary btn-lg">Details</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;

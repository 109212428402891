import React, { useState, useEffect } from "react";
import Profilee from "../images/Profilee.png";
import { Link, useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import FavoriteBorderTwoToneIcon from '@mui/icons-material/FavoriteBorderTwoTone';
import WorkOutlineTwoToneIcon from '@mui/icons-material/WorkOutlineTwoTone';
import PasswordTwoToneIcon from '@mui/icons-material/PasswordTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import axios from '../axios';

const SideBar = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = localStorage.getItem('userId');
        const response = await axios.get('/user/${userId}'); // Adjust the endpoint as per your backend route
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const logoutHandler = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/auth/logout');
      setUser(null);
      localStorage.clear();
      alert("Logged out");
      navigate("/");
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="w-full h-max mt-24 mb-6 md:border-2 bg-gray-100 md:rounded-lg">
      {user ? (
        <div className="bg-transparent w-full h-max border-red-700 mt-6">
          <div className="flex justify-center items-center w-42 h-42">
            <img
              alt="Profile"
              src={user.photoURL || Profilee}
              className="w-16 h-16 md:w-42 md:h-42 object-cover rounded-full"
            />
          </div>
          <div>
            <p className="text-2xl font-bold font-sarif -mb-1 text-center">
              {user.f_name}
            </p>
            <p className="text-gray-700 text-center">{user.jobtitle || "Software Engineer"}</p>
          </div>
        </div>
      ) : (
        <div className="bg-transparent w-full h-max border-red-700">
          <div className="flex justify-center items-center w-42 h-42 pt-4">
            <img
              alt="Profile"
              src={Profilee}
              className="w-24 h-24 object-cover rounded-full"
            />
          </div>
          <div>
            <p className="text-2xl font-bold font-sarif -mb-1 text-center">
              Loading...
            </p>
            <p className="text-gray-700 text-center">Please wait</p>
          </div>
        </div>
      )}
      
      <div className="bg-gray-100 border shadow-lg rounded-md pt-4">
        {user && (
          <>
            <Link to="/profile" className="w-full text-center text-xl font-bold text-blue-600 hover:text-orange-600">
              <PersonIcon className="ml-6 mr-2" /> View Profile
            </Link>
            <hr className="border-gray-500" />
            <Link to="/updateprofile" className="w-full text-center text-xl font-bold text-blue-600 hover:text-orange-600">
              <HowToRegIcon className="ml-6 mr-2" /> Update Profile
            </Link>
            <hr className="border-gray-500" />
          </>
        )}
        <Link to="/alljobs" className="w-full text-center text-xl font-bold text-blue-600 hover:text-orange-600">
          <BusinessCenterOutlinedIcon className="ml-6 mr-2" /> Jobs
        </Link>
        <hr className="border-gray-500" />
        <Link to="/resume" className="w-full text-center text-xl font-bold text-blue-600 hover:text-orange-600">
          <DescriptionTwoToneIcon className="ml-6 mr-2" /> View Resume
        </Link>
        <hr className="border-gray-500" />
        <Link to="/saved-jobs" className="w-full text-center text-xl font-bold text-blue-600 hover:text-orange-600">
          <FavoriteBorderTwoToneIcon className="ml-6 mr-2" /> Saved Jobs
        </Link>
        <hr className="border-gray-500" />
        <Link to="/applied-jobs" className="w-full text-center text-xl font-bold text-blue-600 hover:text-orange-600">
          <WorkOutlineTwoToneIcon className="ml-6 mr-2" /> Applied Jobs
        </Link>
        <hr className="border-gray-500" />
        <Link to="/forgotpassword" className="w-full text-center text-xl font-bold text-blue-600 hover:text-orange-600">
          <PasswordTwoToneIcon className="ml-6 mr-2" /> Change Password
        </Link>
        <hr className="border-gray-500" />
        {user && (
          <>
            <Link onClick={logoutHandler} className="w-full text-center text-xl font-bold text-blue-600 hover:text-orange-600">
              <LogoutTwoToneIcon className="ml-6 mr-2" /> Logout
            </Link>
            <hr className="border-gray-500 mb-0" />
          </>
        )}
      </div>
    </div>
  );
};

export default SideBar;



// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { signOut } from "firebase/auth";
// import { auth } from "../Firebase";
// import { useNavigate } from "react-router-dom";
// import Profilee from "../images/Profilee.png";
// import { onAuthStateChanged } from "firebase/auth";
// import PersonIcon from '@mui/icons-material/Person';
// import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
// import FavoriteBorderTwoToneIcon from '@mui/icons-material/FavoriteBorderTwoTone';
// import WorkOutlineTwoToneIcon from '@mui/icons-material/WorkOutlineTwoTone';
// import PasswordTwoToneIcon from '@mui/icons-material/PasswordTwoTone';
// import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
// import HowToRegIcon from '@mui/icons-material/HowToReg';
// import axios from "axios";

// const MenuCard = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const navigate = useNavigate();
//   const [user, setUser] = useState();

//   useEffect(() => {
//     console.log(localStorage.getItem('userId'));
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser);
//     });

//     return () => {
//       unsubscribe();
//     };
//   }, []);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   // const logoutHandler = async (e) => {
//   //   e.preventDefault();
//   //   await signOut(auth);
//   //   navigate("/");
//   // };


//   const logoutHandler = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post('http://localhost:8081/auth/logout');
      
      
//       await signOut(auth);
//      setUser(null);
//      console.log(localStorage.getItem('userId'));
//      console.log(user,"from menu")
//       alert("logged out");
//       navigate("/");
//     } catch (error) {
//       console.error('Error logging out:', error);
//     }
//   };

//   return (
//     <div className="relative">
//       <div onClick={toggleMenu}>
//         <img
//           className="w-12 h-12 border-2 border-black rounded-full cursor-pointer"
//           src={user?user.photoURL:Profilee}
//           alt="Profile"
//         />
//       </div>
//       <div className={`absolute top-16 -right-9 w-64 pt-4 bg-white border border-gray-300 shadow-lg rounded-md z-50 transition-all duration-500 ease-in-out ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
//         {/* <Link to="/" className="block px-4 py-2 text-gray-800 hover:bg-gray-100"><PersonIcon className="mr-2"/> Jobs</Link>
//         <hr className="border-gray-500" /> */}
//         <Link to="/userprofile" className="block px-4 py-2 text-gray-800 "><PersonIcon className="mr-2"/> View Profile</Link>
//         <hr className="border-gray-500" />
//         <Link to="/updateprofile" className="block px-4 py-2 text-gray-800 "> <HowToRegIcon className="mr-2"/> Update Profile</Link>
//         <hr className="border-gray-500 "  />
//         <Link to="" className="md:hidden px-4 py-2 text-gray-800  "><DescriptionTwoToneIcon className="mr-2"/> View Resume</Link>
//         <hr className="border-gray-500 " />
//         <Link  className="md:hidden w-full text-left px-4 py-2 text-gray-800  "><FavoriteBorderTwoToneIcon className="mr-2"/> Saved Jobs</Link>
//         <hr className="border-gray-500 " />
//         <Link  className="md:hidden w-full text-left px-4 py-2 text-gray-800  "><WorkOutlineTwoToneIcon className="mr-2"/> Applied Jobs </Link>
//         <hr className="border-gray-500 " />
//         <Link  className=" sm:block md:hidden w-full text-left px-4 py-2 text-gray-800  "><PasswordTwoToneIcon className="mr-2"/> Change Passoword</Link>
//         <hr className="border-gray-500" />
//         <Link onClick={logoutHandler} className="block w-full text-left px-4 py-2 text-gray-800 "><LogoutTwoToneIcon className="mr-2"/> Logout</Link>
//         <hr className="border-gray-500" />
//       </div>
//     </div>
//   );
// }

// export default MenuCard;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../Firebase";
import { useNavigate } from "react-router-dom";
import Profilee from "../images/Profilee.png";
import { onAuthStateChanged } from "firebase/auth";
import PersonIcon from '@mui/icons-material/Person';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import FavoriteBorderTwoToneIcon from '@mui/icons-material/FavoriteBorderTwoTone';
import WorkOutlineTwoToneIcon from '@mui/icons-material/WorkOutlineTwoTone';
import PasswordTwoToneIcon from '@mui/icons-material/PasswordTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import axios from "../axios";

const MenuCard = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const [user, setUser] = useState();

  useEffect(() => {
    console.log(localStorage.getItem('userId'));
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const logoutHandler = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/auth/logout');
      await signOut(auth);
      setUser(null);
      console.log(localStorage.getItem('userId'));
      console.log(user, "from menu")
      alert("logged out");
      navigate("/");
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="relative">
      <div onClick={toggleMenu}>
        <img
          className="w-10 h-10 border-2 border-black rounded-full cursor-pointer"
          src={user ? user.photoURL : Profilee}
          alt="Profile"
        />
      </div>
      <div className={`absolute top-16 right-0 w-64 pt-4 bg-white border border-gray-300 shadow-md rounded-md z-50 transition-all duration-500 ease-in-out ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
        <Link to="/userprofile" className="block px-4 py-2 text-gray-800" onClick={closeMenu}><PersonIcon className="mr-2" /> View Profile</Link>
        <hr className="border-gray-500" />
        <Link to="/updateprofile" className="block px-4 py-2 text-gray-800" onClick={closeMenu}> <HowToRegIcon className="mr-2" /> Update Profile</Link>
        <hr className="border-gray-500 " />
        <Link to="" className="block md:hidden  px-4 py-2 text-gray-800" onClick={closeMenu}><DescriptionTwoToneIcon className="mr-2" /> View Resume</Link>
        <hr className="border-gray-500 block md:hidden " />
        <Link className=" block md:hidden  w-full text-left px-4 py-2 text-gray-800" onClick={closeMenu}><FavoriteBorderTwoToneIcon className="mr-2" /> Saved Jobs</Link>
        <hr className="border-gray-500 block md:hidden  " />
        <Link className="block md:hidden  w-full text-left px-4 py-2 text-gray-800" onClick={closeMenu}><WorkOutlineTwoToneIcon className="mr-2" /> Applied Jobs </Link>
        <hr className="border-gray-500 block md:hidden " />
        <Link className="block md:hidden  w-full text-left px-4 py-2 text-gray-800" onClick={closeMenu}><PasswordTwoToneIcon className="mr-2" /> Change Passoword</Link>
        <hr className="border-gray-500 block md:hidden " />
        <Link onClick={logoutHandler} className="block w-full text-left px-4 py-2 text-gray-800" ><LogoutTwoToneIcon className="mr-2" /> Logout</Link>
        <hr className="border-gray-500 mb-0" />
      </div>
    </div>
  );
}

export default MenuCard;

import React, { useEffect, useState } from "react";
import { auth } from "../Firebase";
import Header from "../components/Header";
import { onAuthStateChanged } from "firebase/auth";
import "../components/assests/Home.css";
import "../components/assests/seekerprofile.css";
import Loader from "../components/Loader";
import SideBar from "./SideBar";
import UserProfile from "./Profile";

const SeekerProfile = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      setUser(userId);
      console.log("uid at userprofile",userId);
      setLoading(false);
    } else {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        console.log("user at auth",currentUser)
        setLoading(false);
      });
      return () => {
        unsubscribe();
      };
    }
  }, []); // Empty dependency array ensures that this effect runs only once when component mounts

  if (loading) {
    return (
      <div className="App" style={{ top: 100 }}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Header isLoggedIn={!!user} />
      <div className="w-full flex justify-center gap-x-5 ">
        <div className="hidden md:block w-64 m-2">
          <SideBar />
        </div>

        <div className=" w-full m-2 md:m-0 md:w-3/5">
          <UserProfile />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default SeekerProfile;

// import React, { useState } from "react";
// import { Link,useNavigate } from "react-router-dom";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import job360 from '../images/job360.png'
// import axios from "axios";


// function SignUp() {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({ name: '', email: '', password: '' });

//   const handleData = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:8081/auth/signup', formData);
//       console.log(response.data);
//       alert("Registered successfully!");
//       navigate('/login');
//     } catch (error) {
//       if (error.response.status === 400) {
//         console.log("User already registered with this email.");
//         // Show a message to the user indicating that the email is already registered
//       } else {
//         console.error("Error:", error.response.data);
//       }
//   }
// }


//   return (
//     <div className="w-full h-full flex flex-col">
//       <Header />

//       <div className=" mt-16 ">
//         <div className="max-w-lg mx-auto  my-10 bg-white p-8 rounded-xl shadow border shadow-slate-300">
//           <div className="flex justify-center items-center">
//             <img src={job360} alt="changes" className=" w-max h-20 object-cover " />
//           </div>
//           <h1 className="text-4xl font-medium">Sign Up</h1>
//           <p className="text-slate-500">Register Your Account here!</p>
//           <form onSubmit={handleSubmit}>
//             <div className="flex flex-col space-y-3">
//               <label htmlFor="name">
//                 <p className="font-medium text-slate-700 mb-0">Name required*</p>
//                 <input id="name" name="name" type="text" onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter your name" />
//               </label>
//               <label htmlFor="email">
//                 <p className="font-medium text-slate-700 mb-0">Email address*</p>
//                 <input id="email" name="email" type="email" onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter email address" />
//               </label>
//               <label htmlFor="password">
//                 <p className="font-medium text-slate-700  mb-0">Password*</p>
//                 <input id="password" name="password" type="password" onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter your password" />
//               </label>

//               <button type="submit" className="w-full py-2 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center">
//                 <span>Register</span>
//               </button>
//               <p className="text-center">Go back to login <Link to="/login" className="text-indigo-600 font-medium inline-flex space-x-1 items-center">
//                 <span>
//                   <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
//                     <path strokeLinecap="round" strokeLinejoin="round" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
//                   </svg>
//                 </span>
//                 <span>Login </span>
//               </Link>
//               </p>
//             </div>
//           </form>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default SignUp;



import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import job360 from '../images/job360.png'
// import axios from "axios";
import toast from 'react-hot-toast';
import axios from '../axios';



function SignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ role:"user" , email: '', password: ''}); // Default to user type

  const handleData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8081/auth/createuser', formData);
      const {id} = response.data;
      localStorage.setItem('userId', id);
      localStorage.setItem('email', formData.email);

      console.log("response data", response.data);
      console.log(formData.email)
      toast.success("registered Successfully! ");
      // alert("Registered successfully!");
      // navigate(`/userregister/${id}`);
      navigate("/userregister");


    } catch (error) {
    //  alert(error.message)
     console.log(error.message);
     toast.error(`Sign up Failed, Try Again!.${error.message}`)
    }
  };


  return (
    <div className="w-full h-full flex flex-col">
      <Header />

      <div className="mt-16">
        <div className="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow border shadow-slate-300">
          <div className="flex justify-center items-center">
            <img src={job360} alt="changes" className="w-max h-20 object-cover" />
          </div>
          <h1 className="text-4xl font-medium">Sign Up</h1>
          <p className="text-slate-500">Register Your Account here!</p>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-3">

              <label htmlFor="userType" className="mb-0"> User Type* </label>
                <select
                   required
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleData}
                  className="w-full mt-0 py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                >
                  <option className="w-8" value="admin">Admin</option>
                  <option className="w-8" value="user">User</option>
                </select>
              {/* <label htmlFor="name">
                <p className="font-medium text-slate-700 mb-0">Name required*</p>
                <input id="name" name="name" type="text" onChange={handleData} autoComplete="off" className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter your name" />
              </label> */}
              <label htmlFor="email">
                <p className="font-medium text-slate-700 mb-0">Email address*</p>
                <input id="email" name="email" type="email" onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter email address" />
              </label>
              <label htmlFor="password">
                <p className="font-medium text-slate-700 mb-0">Password*</p>
                <input id="password" name="password" type="password" onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter your password" />
              </label>
              <button type="submit" className="w-full py-2 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center">
                <span>Register</span>
              </button>
              <p className="text-center">Go back to login <Link to="/login" className="text-indigo-600 font-medium inline-flex space-x-1 items-center">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                  </svg>
                </span>
                <span>Login </span>
              </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignUp;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from '../axios';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/auth/forgot-password', { email });
            setMessage('Password reset link has been sent to your email.');
            setError('');
            // Uncomment the next line if you want to navigate to another page after successful request
            navigate('/login');
        } catch (error) {
            console.error('Forgot password request error:', error.response || error.message);
            setError('Error sending reset password link. Please try again.');
            setMessage('');
        }
    };

    return (
        <div className="w-full h-full flex flex-col ">
            <Header />
            <div className="w-96 mx-auto my-10 mt-28 bg-white p-8 border-2 rounded-xl shadow shadow-slate-300">
                <h1 className="text-4xl font-medium">Reset password</h1>
                <p className="text-slate-500">Fill up the form to reset the password</p>

                <form className="my-10" onSubmit={handleSubmit}>
                    <div className="flex flex-col space-y-5">
                        <label htmlFor="email">
                            <p className="font-medium text-slate-700 pb-1 mb-1">Email address</p>
                            <input 
                                id="email" 
                                name="email" 
                                type="email" 
                                value={email}
                                onChange={handleChange} 
                                className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" 
                                placeholder="Enter email address" 
                                required 
                            />
                        </label>

                        <button 
                            type="submit" 
                            className="w-full py-2 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                            </svg>

                            <span>Reset password</span>
                        </button>
                        {message && <p className="text-green-500 text-center">{message}</p>}
                        {error && <p className="text-red-500 text-center">{error}</p>}
                        <p className="text-center">Not registered yet? <Link to="/signup" className="text-indigo-600 font-medium inline-flex space-x-1 items-center"><span>Register now </span><span><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg></span></Link></p>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
}

export default ForgotPassword;

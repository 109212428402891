// import React,{useState,useEffect} from 'react';
// import "./App.css";
// import About from './pages/About/About';
// import Home from "./pages/Home";
// import { BrowserRouter, Routes, Route,useNavigate } from "react-router-dom";
// import Service  from "./pages/Service";
// import LoginForm from "./forms/LoginForm";
// import SignUp from "./forms/SignUP";
// import Recruiter from "./employer/Recruiter";
// import SeekerProfile from "./employee/SeekerProfile";
// import { AuthContextProvider } from "./Context/AuthContext";
// import ProtectedRoute from "./Routes/ProtectedRoute";
// import UserRegistration from "./forms/Registration";
// import ChangePassword from "./forms/ChangePassword";
// import Update from './employee/ProfileUpdate';
// import Profilee from './employee/Profile';
// import ForgotPassword from './forms/ForgotPassword';

// import JobDetails from './Job/JobDetails';
// import Error404 from './components/Error404';
// // import Dashboardtesting from './dashboarLayout/Dashboar';
// // import RootLayout from './dashboarLayout/RootLayout';





// function App() {
//   const [user, setUser] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const storedUserId = localStorage.getItem('userId');
//     if (storedUserId !== null) {
//       setUser(storedUserId);
//       console.log("at app.js ",user);
//     } else {
//       console.log("at app.js ",storedUserId);
//       setUser(null);
//     }
//   }, []); // Empty dependency array to run the effect only once on component mount

//   console.log(user);


//   return (
//       <AuthContextProvider>
//     <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Home/>}></Route>
//           <Route path='*' element={<Error404/>}></Route>

//           {/* //dashboard start */}
//           {/* <Route path="/dashboard" element={<RootLayout/>}>
//           <Route path="/dashboard"  element={<Dashboardtesting />}>
//            <Route path="/dashboard/userprofile" element={<SeekerProfile />} />
//            <Route path="/dashboard/updateprofile" element={<Update />} />
//           </Route> */}
//           <Route path="/login" element={<LoginForm />}></Route>
//           <Route path="/about" element={<About/> }></Route>
//           <Route path="/services" element={<Service />}></Route>
//           <Route path="/signup" element={<SignUp />}></Route>
//           <Route path="/forgotpassword" element={<ForgotPassword/>}></Route>
//           <Route path="/userregister" element={<UserRegistration/>}></Route>
//           <Route path="/changepassword" element={<ChangePassword/>}></Route>
//           <Route path="/profile" element={<ProtectedRoute><Profilee/></ProtectedRoute>}></Route>
//           <Route path="/updateprofile" element={<ProtectedRoute><Update/></ProtectedRoute>}></Route> 
//            <Route path="/userprofile" element={user?(<SeekerProfile />):(navigate('/'))}></Route> 
//            {/* <Route path="/userprofile" element={localStorage.getItem('userId')?<SeekerProfile />:<Home/>}></Route> */}
//           {/* <Route path="/userprofile" element={<SeekerProfile />}></Route> */}
//           <Route path="/recruiter" element={<Recruiter />}></Route>
//           <Route path="/jobdetails" element={<JobDetails/>}></Route> 
//           {/* <Route path="/jobapply" element={<JobApply/>}></Route>  */}


//         </Routes>
//     </BrowserRouter>
//       </AuthContextProvider>
//   );
// }

// export default App;
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import "./App.css";
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import About from './pages/About/About';
import Home from "./pages/Home";
import Service from "./pages/Service";
import LoginForm from "./forms/LoginForm";
import SignUp from "./forms/SignUP";
import Recruiter from "./recruiter/Recruiter";
import SeekerProfile from "./employee/SeekerProfile";
import UserRegistration from "./forms/Registration";
import ChangePassword from "./forms/ChangePassword";
import Update from './employee/ProfileUpdate';
import Profilee from './employee/Profile';
import ForgotPassword from './forms/ForgotPassword';
import JobDetails from './Job/JobDetails';
import Error404 from './components/Error404';
import MyJobs from './recruiter/MyJobs';
import Applications from './recruiter/Applications';
import AddedJobs from './recruiter/AllJobs';

function App() {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    setUser(storedUserId);
    console.log(storedUserId);
    if (storedUserId !== null) {
      setRole(localStorage.getItem('role'));
      console.log("app", user,"user",storedUserId,"id",role, localStorage.getItem('role'))
    } else {
      setUser(null);
    }
  },[user,role]);

  return (
    <BrowserRouter>
      <Toaster>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <button onClick={() => toast.dismiss(t.id)}><span className="text-red-600 font-bold">X</span></button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/userregister" element={<UserRegistration />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/profile" element={user ? <Profilee /> : <Navigate to='/' />} />
        <Route path="/userprofile" element={user ? <SeekerProfile /> : <Navigate to="/" />} />
        <Route path="/updateprofile" element={user ? <Update /> : <Navigate to="/" />} />
        <Route path="/jobdetails/:id" element={<JobDetails />} />
        <Route path="/recruiter" element={user ? <Recruiter /> : <Navigate to="/" />} />
        <Route path="/myjobs" element={user ? <MyJobs /> : <Navigate to="/" />} />
        <Route path="/applications" element={<Applications />} />
        <Route path="/alljobs" element={user ? <AddedJobs/> : <Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

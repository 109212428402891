import React from "react";
import "../../App.css";
import aboutpic3 from '../../images/aboutpic3.jpg'
import aboutpic2 from '../../images/aboutpic2.jpg'
import about1pic from '../../images/about1pic.jpg'

const Hero = () => {
  return (
    <div >

      <section className="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] bg-white dark:bg-dark">
        <div className="container mx-auto ">
          <div className="row ">
            <div className="col-md-6 pt-4">
              <div className="mt-10 lg:mt-0">
                <span className="block mb-4 text-4xl font-semibold text-primary">
                  Why Choose Us
                </span>
                <h1 className="mb-4 md:hidden  text-xl  text-center font-sans ">
                  <span >Jobs360degree</span>
                </h1>

                <p className="mb-5  text-xl text-body-color dark:text-dark-6 text-pretty">
                  <span className="font-bold text-3xl" style={{ color: "#1D46F5" ,fontSize: 18}}>Jobs360Degree</span> is a Swadeshi job portal dedicated to connecting school teachers
                  with rewarding opportunities. Founded on the principles of efficiency and responsiveness,
                  our platform addresses the unique needs of educators, ensuring a
                  seamless and effective job search experience. With a two-way rating system
                  and automatic application cancellation within a week.
                  Jobs360Degree is committed to providing a platform that values both
                  the teachers and the institutions seeking their expertise.
                </p>
                <p className="mb-8 text-base text-body-color dark:text-dark-6">
                  <span style={{ color: "#1D46F5", fontWeight: "bold", fontSize: 26 }}>A</span> best place to connect worlds' Organisations,  Institutions( IIT / NIT), Schools, Companies and Students in one place.
                </p>
                <a
                  href="/"
                  className="inline-flex items-center justify-center py-3 text-base font-medium text-center text-white border border-transparent rounded-md px-7 bg-primary hover:bg-opacity-90"
                >
                  Get Started
                </a>
              </div>
            </div>

            <div className=" col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <div className="py-3 sm:py-4">
                    <img src={about1pic}  alt="" className="w-full rounded-2xl" />
                  </div>
                  <div className="py-3 sm:py-4">
                    <img src={aboutpic2} alt="" className="w-full rounded-2xl" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="relative z-10 my-28">
                    <img src={aboutpic3} alt="" className="w-full rounded-2xl" />
                    <span className="absolute -right-7 -bottom-7 z-[-1]">

                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;

import React from "react";
import { useState, useEffect } from "react";
import { auth } from "../../Firebase";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../../App.css";
import "../../components/assests/Home.css";
import Hero from "./Hero";



const About = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <div >
      <Header isLoggedIn={!!user} />
      <Hero/>
      <Footer />
    </div>
  );
};

export default About;

import React, { useEffect, useState } from "react";
// import { signOut } from "firebase/auth";
// import { useNavigate } from "react-router-dom";
import { auth } from "../Firebase";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { onAuthStateChanged } from "firebase/auth";
import "../components/assests/Home.css";
import "../components/assests/seekerprofile.css";
import Loader from "../components/Loader";
import SideBar from "./SideBar";
import UpdateProfile from "./Updateprofile";


const Update = () => {
  // const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [isLoggedIn, setLoggedIn] = useState(false);

  // const logoutHandler = async (e) => {
  //   e.preventDefault();
  //   await signOut(auth);
  //   navigate("/");
  // };

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if(userId!=null){
      setUser(userId);
      setLoading(false);
    }
    else{
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    }

  }, []);

  if (loading) {
    return(
      <div class="App" style={{ top: 100 }}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <Header isLoggedIn={!!user} />
      <div className="w-full flex justify-center gap-x-5 ">
        <div className="hidden sm:block w-64  m-2 ">
          <SideBar />
        </div>

        <div className="w-full m-2 md:m-0 md:w-3/5">
          <UpdateProfile/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Update;

// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import Header from '../components/Header';
// import { Link, useParams } from 'react-router-dom';
// import FavoriteBorderTwoTone from '@mui/icons-material/FavoriteBorderTwoTone';
// import JobApply from './JobApply';

// const JobDetails = () => {
//     const { id } = useParams();
//     const [job, setJob] = useState(null);

//     useEffect(() => {
//         const fetchJob = async () => {
//             try {
//                 const response = await axios.get(`http://localhost:8081/jobs/getjobbyid/${id}`); // Replace YOUR_BACKEND_ENDPOINT with the actual endpoint
//                 setJob(response.data);
//             } catch (error) {
//                 console.error('Error fetching job:', error);
//             }
//         };

//         fetchJob();
//     }, [id]);

//     if (!job) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <>
//             <Header />
//             <section id="jobdetail" className=" w-full section-4 bg-2 pt-12">
//                 <div className="container pt-5 mt-6">
//                     <div className="row">
//                         <div className="col">
//                             <nav aria-label="breadcrumb" className=" rounded-3 p-3">
//                                 <ol className="breadcrumb mb-0">
//                                     <li className="breadcrumb-item"><Link to="/"><i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;Back to Jobs</Link></li>
//                                 </ol>
//                             </nav>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="container job_details_area ">
//                     <div className="row pb-5">
//                         <div className="col-md-4">
//                             <div className="card shadow border">
//                                 <div className="job_sumary">
//                                     <div className="summery_header  pt-2 pl-2">
//                                         <h3>Job Summery</h3>
//                                     </div>
//                                     <div className="job_content pt-2">
//                                         <ul >
//                                             <li>Published on: <span>12 April, 2024</span></li>
//                                             <li>Vacancy: <span>2 Position</span></li>
//                                             <li>Salary: <span> {job.Salary}</span></li>
//                                             <li>Location: <span>{job.location}</span></li>
//                                             <li>Job Nature: <span> {job.type}</span></li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="card shadow my-4 border">
//                                 <div className="job_sumary">
//                                     <div className="summery_header pt-2 pl-2">
//                                         <h3>Company Details</h3>
//                                     </div>
//                                     <div className="job_content pt-1">
//                                         <ul>
//                                             <li>Name: <span>{job.company}</span></li>
//                                             <li>Locaion: <span>{job.location}</span></li>
//                                             <li>Webite: <span><a href={job.url}>click here </a></span></li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className="col-md-8">
//                             <div className="card shadow border rounded-md p-8">
//                                 <div className="job_details_header pl-5">
//                                     <div className="single_jobs white-bg d-flex justify-content-between">
//                                         <div className="jobs_left d-flex align-items-center">

//                                             <div className="jobs_conetent">
                                               
//                                                     <p className="text-blue-600 text-3xl  font-bold">{job.title}</p>
                                              
//                                                 <div className="links_locat d-flex align-items-center pl-4">
//                                                     <div className="location">
//                                                         <p> <i className="fa fa-map-marker"></i> {job.location}</p>
//                                                     </div>
//                                                     <div className="location">
//                                                         <p> <i className="fa fa-clock-o"></i> {job.type}</p>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="jobs_right">
//                                             <div className="apply_now">
//                                                 <a className="heart_mark" href="/"> <i className="fa fa-heart-o" aria-hidden="true"></i></a>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="descript_wrap white-bg pl-5">
//                                     <div className="single_wrap">
//                                         <h4>About-</h4>
//                                         <p className=" px-4 text-justify"><span className="pl-2">There</span> are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
//                                         <p className='px-4 text-justify'>Variations of passages of lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
//                                     </div>
//                                 </div>

//                                 <div className="descript_wrap white-bg pl-5">
//                                     <div className="single_wrap">
//                                         <h4>Job description-</h4>
//                                         <p className=" px-4 text-justify"><span className="pl-2">There</span> are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
//                                         <p className='px-4 text-justify'>Variations of passages of lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
//                                     </div>
//                                     <div className="single_wrap">
//                                         <h4>Responsibility-</h4>
//                                         <ul className="list-disc">
//                                             <li>The applicants should have experience in the following areas.</li>
//                                             <li>Have sound knowledge of commercial activities.</li>
//                                             <li>Leadership, analytical, and problem-solving abilities.</li>
//                                             <li>Should have vast knowledge in IAS/ IFRS, Company Act, Income Tax, VAT.</li>
//                                         </ul>
//                                     </div>
//                                     <div className="single_wrap">
//                                         <h4>Qualifications-</h4>
//                                         <ul className="list-disc ">
//                                             <li>The applicants should have experience in the following areas.</li>
//                                             <li>Have sound knowledge of commercial activities.</li>
//                                             <li>Leadership, analytical, and problem-solving abilities.</li>
//                                             <li>Should have vast knowledge in IAS/ IFRS, Company Act, Income Tax, VAT.</li>
//                                         </ul>
//                                     </div>
//                                     <div className="single_wrap">
//                                         <h4>Benefits-</h4>
//                                         <p className="px-4 text-justify">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing.</p>
//                                     </div>
//                                     <div className="border-bottom"></div>
//                                     <div className="w-full pt-3 text-center flex justify-evenly">
//                                         <div className=" w-36 bg-blue-500 rounded-md text-xl text-white text-center pt-2 border"><FavoriteBorderTwoTone/> Save</div>
//                                        {/* <div><JobApply/> </div>  */}
//                                        <JobApply jobId={id} />
//                                     </div> 
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
                

                



//             </section>
//         </>
//     )
// }

// export default JobDetails
// JobDetails.js

import React, { useState, useEffect } from 'react';
import axios from '../axios';
import Header from '../components/Header';
import { Link, useParams } from 'react-router-dom';
import FavoriteBorderTwoTone from '@mui/icons-material/FavoriteBorderTwoTone';
import JobApply from './JobApply';

const JobDetails = () => {
    const { id } = useParams();
    const [job, setJob] = useState(null);

    useEffect(() => {
        const fetchJob = async () => {
            try {
                const response = await axios.get(`/jobs/getjobbyid/${id}`);
                setJob(response.data);
            } catch (error) {
                console.error('Error fetching job:', error);
            }
        };

        fetchJob();
    }, [id]);

    if (!job) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Header />
            <section id="jobdetail" className="w-full section-4 bg-2 pt-12">
                <div className="container pt-5 mt-6">
                    <div className="row">
                        <div className="col">
                            <nav aria-label="breadcrumb" className="rounded-3 p-3">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><Link to="/"><i className="fa fa-arrow-left" aria-hidden="true"></i> &nbsp;Back to Jobs</Link></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container job_details_area">
                    <div className="row pb-5">
                        <div className="col-md-4">
                            <div className="card shadow border">
                                <div className="job_summary">
                                    <div className="summary_header pt-2 pl-2">
                                        <h3>Job Summary</h3>
                                    </div>
                                    <div className="job_content pt-2">
                                        <ul>
                                            <li>Published on: <span>{job.created_at}</span></li>
                                            <li>Vacancy: <span>{job.vacancy}</span></li>
                                            <li>Salary: <span>{job.salary}</span></li>
                                            <li>Location: <span>{job.location}</span></li>
                                            <li>Job Nature: <span>{job.job_nature}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="card shadow my-4 border">
                                <div className="job_summary">
                                    <div className="summary_header pt-2 pl-2">
                                        <h3>Company Details</h3>
                                    </div>
                                    <div className="job_content pt-1">
                                        <ul>
                                            <li>Name: <span>{job.company_name}</span></li>
                                            <li>Location: <span>{job.company_location}</span></li>
                                            <li>Website: <span><a href={job.ebsite}>click here</a></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card shadow border rounded-md p-8">
                                <div className="job_details_header pl-5">
                                    <div className="single_jobs white-bg d-flex justify-content-between">
                                        <div className="jobs_left d-flex align-items-center">
                                            <div className="jobs_content">
                                                <p className="text-blue-600 text-3xl font-bold">{job.title}</p>
                                                <div className="links_location d-flex align-items-center pl-4">
                                                    <div className="location">
                                                        <p><i className="fa fa-map-marker"></i> {job.location}</p>
                                                    </div>
                                                    <div className="location">
                                                        <p><i className="fa fa-clock-o"></i> {job.job_nature}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="jobs_right">
                                            <div className="apply_now">
                                                <a className="heart_mark" href="/"><i className="fa fa-heart-o" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="description_wrap white-bg pl-5">
                                    <div className="single_wrap">
                                        <h4>About-</h4>
                                        <p className="px-4 text-justify">{job.description}</p>
                                    </div>
                                </div>
                                {/* Add more job details sections as needed */}
                                <div className="border-bottom"></div>
                                <div className="w-full pt-3 text-center flex justify-evenly">
                                    <div className="w-36 bg-blue-500 rounded-md text-xl text-white text-center pt-2 border"><FavoriteBorderTwoTone/> Save</div>
                                    <JobApply jobId={id} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default JobDetails;

import React, { useState } from "react";
import "./assests/Home.css";
import "../App.css";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from '../axios'; // Import axios for handling logout

const NavBar = ({ isLoggedIn, showNavBar }) => {
  const [employerMenuOpen, setEmployerMenuOpen] = useState(false);
  const [candidateMenuOpen, setCandidateMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleEmployerMenuEnter = () => {
    setEmployerMenuOpen(true);
    setCandidateMenuOpen(false);
  };

  const handleEmployerMenuLeave = () => {
    setEmployerMenuOpen(false);
  };

  const handleCandidateMenuEnter = () => {
    setCandidateMenuOpen(true);
    setEmployerMenuOpen(false);
  };

  const handleCandidateMenuLeave = () => {
    setCandidateMenuOpen(false);
  };

  const handleEmployerLinkClick = () => {
    setEmployerMenuOpen(!employerMenuOpen);
  };

  const handleCandidateLinkClick = () => {
    setCandidateMenuOpen(!candidateMenuOpen);
  };

  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleLogout = async () => {
    try {
      await axios.post('/auth/logout'); // Assuming you have a logout endpoint
      navigate("/");
      window.location.reload(); // Refresh the page to update the UI
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <div className={`border-1 w-full h-max bg-transparent flex justify-between bg-black mb-3 ${showNavBar ? 'flex-col' : 'hidden md:flex'} flex space-x-4 md:items-center`}>
      <hr className="border-gray-400 mt-0" />
      {isLoggedIn ? (
        <div className="flex flex-col md:flex-row md:space-x-4 pb-2 space-y-2 md:items-center">
          <Link to="/" className="font-bold text-xl" onClick={handleLinkClick}>
            Home
          </Link>
          <hr className="border-gray-400 md:hidden" />

          <div onMouseEnter={handleEmployerMenuEnter} onMouseLeave={handleEmployerMenuLeave}>
            <Link to="" className="font-bold inline-flex text-xl" onClick={handleEmployerLinkClick}>
              Employer<span className="text-blue-700"><KeyboardArrowDownIcon /></span>
            </Link>
            <div className={`absolute ${employerMenuOpen ? 'block' : 'hidden'} bg-white rounded-md border shadow-md mt-1 py-1 w-64 md:w-52 z-10`}>
              <Link to="/recruiter" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>Post Job</Link>
            </div>
          </div>
          <hr className="border-gray-400 md:hidden" />

          <div onMouseEnter={handleCandidateMenuEnter} onMouseLeave={handleCandidateMenuLeave}>
            <Link to="" className="font-bold inline-flex text-xl" onClick={handleCandidateLinkClick}>
              Candidate<span className="text-blue-700"><KeyboardArrowDownIcon /></span>
            </Link>
            <div className={`absolute ${candidateMenuOpen ? 'block' : 'hidden'} bg-white border rounded-md shadow-md mt-1 py-1 w-64 md:w-52 z-10`}>
              <Link to="/profile" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>My Profile</Link>
              <hr className="border-gray-400" />
              <Link to="/updateprofile" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>Update Profile</Link>
              <hr className="border-gray-400" />
              <Link to="/myjobs" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>Applied Jobs</Link>
              <hr className="border-gray-400" />
              <Link to="/alljobs" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>Manage Jobs</Link>
              <hr className="border-gray-400 mb-0" />
            </div>
          </div>
          <hr className="border-gray-400 md:hidden" />

          <Link to="/about" className="font-bold text-xl" onClick={handleLinkClick}>
            About
          </Link>
          <hr className="border-gray-400 md:hidden" />
          <Link to="/services" className="font-bold text-xl" onClick={handleLinkClick}>
            Services
          </Link>
          <hr className="border-gray-400 md:hidden" />
          <button
            className="bg-blue-500 px-6 py-2 w-1/2 rounded-lg text-gray-100 font-bold border-2 border-blue-500 hover:bg-white hover:text-blue-500 hover:border-dash hover:border-blue-500"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      ) : (
        <div className="flex flex-col md:flex-row md:space-x-4 pb-2 space-y-2 md:items-center">
          <Link to="/" onClick={handleLinkClick}>
          </Link>
          
          <Link to="/" className="font-bold text-xl" onClick={handleLinkClick}>
            Home
          </Link>
          <hr className="border-gray-400 md:hidden" />

          <div onMouseEnter={handleEmployerMenuEnter} onMouseLeave={handleEmployerMenuLeave}>
            <Link to="" className="font-bold inline-flex text-xl" onClick={handleEmployerLinkClick}>
              Employer<span className="text-blue-700"><KeyboardArrowDownIcon /></span>
            </Link>
            <div className={`absolute ${employerMenuOpen ? 'block' : 'hidden'} bg-white rounded-md border shadow-md mt-1 py-1 w-64 md:w-52 z-10`}>
              <Link to="/recruiter" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>Post Job</Link>
            </div>
          </div>
          <hr className="border-gray-400 md:hidden" />

          <div onMouseEnter={handleCandidateMenuEnter} onMouseLeave={handleCandidateMenuLeave}>
            <Link to="" className="font-bold inline-flex text-xl" onClick={handleCandidateLinkClick}>
              Candidate<span className="text-blue-700"><KeyboardArrowDownIcon /></span>
            </Link>
            <div className={`absolute ${candidateMenuOpen ? 'block' : 'hidden'} bg-white border rounded-md shadow-md mt-1 py-1 w-64 md:w-52 z-10`}>
              <Link to="/userprofile" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>My Profile</Link>
              <hr className="border-gray-400" />
              <Link to="/myjobs" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>Saved Jobs</Link>
              <hr className="border-gray-400" />
              <Link to="/jobsapplied" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>Applied Jobs</Link>
              <hr className="border-gray-400" />
              <Link to="/alljobs" className="block px-4 py-2 hover:bg-gray-200" onClick={handleLinkClick}>Manage Jobs</Link>
              <hr className="border-gray-400 mb-0" />
            </div>
          </div>
          <hr className="border-gray-400 md:hidden" />
          
          <Link to="/about" className="font-bold text-xl" onClick={handleLinkClick}>
            About
          </Link>
          <hr className="border-gray-400 md:hidden" />
          <Link to="/services" className="font-bold text-xl" onClick={handleLinkClick}>
            Services
          </Link>
          <hr className="border-gray-400 md:hidden" />
          <Link
            to="/login"
            className="bg-blue-500 px-6 py-2 w-1/2 rounded-lg text-gray-100 font-bold border-2 border-blue-500 hover:bg-white hover:text-blue-500 hover:border-dash hover:border-blue-500"
            onClick={handleLinkClick}
          >
            Login
          </Link>
        </div>
      )}
    </div>
  );
};

export default NavBar;

import React, { useState, useEffect } from "react";
import axios from "../axios";

const UpdateProfile = () => {
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState(null);
  const [f_name, setf_name] = useState("");
  const [s_name, sets_name] = useState("");
  const [l_name, setl_name] = useState("");
  const [mobile, setmobile] = useState("");
  const [address, setaddress] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [zip, setzip] = useState("");
  const [gender, setgender] = useState("");
  const [education_institute, seteducation_institute] = useState("");
  const [education_degree, seteducation_degree] = useState("");
  const [major, setmajor] = useState("");
  const [degree_duration, setdegree_duration] = useState("");
  const [company_name, setcompany_name] = useState("");
  const [company_designation, setcompany_designation] = useState("");
  const [company_location, setcompany_location] = useState("");
  const [start_date, setstart_date] = useState("");
  const [end_date, setend_date] = useState("");
  const [salary, setsalary] = useState("");
  const [resume_path, setresume_path] = useState("");
  const [currentStudent, setCurrentStudent] = useState(false);
  useEffect(() => {
    const userIdFromStorage = localStorage.getItem('userId');
    setUserId(userIdFromStorage);
    console.log(userIdFromStorage);

    // axios.get(`/user/fetchUserById`)
    
    axios.get(`/user/${userId}`)
      .then(response => {
        const userData = response.data;
        setUser(userData);
        setf_name(userData.f_name || "");
        sets_name(userData.s_name || "");
        setl_name(userData.l_name || "");
        setmobile(userData.mobile || "");
        setaddress(userData.address || "");
        setcity(userData.city || "");
        setstate(userData.state || "");
        setcountry(userData.country || "");
        setzip(userData.zip || "");
        setgender(userData.gender || "");
        seteducation_institute(userData.education_institute || "");
        seteducation_degree(userData.education_degree || "");
        setmajor(userData.major || "");
        setdegree_duration(userData.degree_duration || "");
        setcompany_name(userData.company_name || "");
        setcompany_designation(userData.company_designation || "");
        setcompany_location(userData.company_location || "");
        setstart_date(userData.start_date || "");
        setend_date(userData.end_date || "");
        setsalary(userData.salary || "");
        setresume_path(userData.resume_path || "");
      })
      .catch(error => {
        console.error("There was an error fetching the user data!", error);
      });
  }, [userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedProfile = {
      f_name,
      s_name,
      l_name,
      mobile,
      address,
      city,
      state,
      country,
      zip,
      gender,
      education_institute,
      education_degree,
      major,
      degree_duration,
      company_name,
      company_designation,
      company_location,
      start_date,
      end_date,
      salary,
      resume_path
    };

    // axios.put(`/user/updateUser`, updatedProfile)
    axios.put(`/user/${userId}`, updatedProfile)
      .then(response => {
        console.log("Profile updated successfully:", response.data);
        // Handle success, e.g., show success message or redirect
      })
      .catch(error => {
        console.error("There was an error updating the profile!", error);
        // Handle error, e.g., display error message
      });
  };

  return (
    <div className="w-full shadow-sm border-2 bg-gray-100 border-black mt-24 mb-4 rounded-md">
    <p className="text-center text-2xl mt-4 underline font-extrabold font-sarif">Update Information</p>
    <form onSubmit={handleSubmit} className="w-full h-max mb-8 flex justify-center items-center flex-wrap gap-6 mt-2">
      <div className="w-full ml-14 mr-14">
        <p className="text-xl font-bold">Personal Information</p>
      </div>
      <div className="w-72">
        <label htmlFor="first-name" className="block text-xl font-medium leading-6 text-gray-900">First Name</label>
        <input type="text" name="f_name" id="f_name" value={f_name} onChange={(e) => setf_name(e.target.value)} className="block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
      </div>
          <div className="w-72">
            <label htmlFor="middle-name" className="block text-xl font-medium leading-6 text-gray-900">Middle Name</label>
            <div className="mt-2">
            <input type="text" name="s_name" id="s_name" value={s_name} onChange={(e) => sets_name(e.target.value)} className="block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="last-name" className="block text-xl font-medium leading-6 text-gray-900">Last Name</label>
            <div className="mt-2">
            <input type="text" name="l_name" id="l_name" value={l_name} onChange={(e) => setl_name(e.target.value)} className="block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          {/* <div className="w-72">
            <label htmlFor="email" className="block text-xl font-medium leading-6 text-gray-900">Email Address</label>
            <div className="mt-2">
              <input type="email" name="email" id="email" autoComplete="given-name" value={email} onChange={(e) => setemail(e.target.value)} className=" block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 curson" />
            </div>
          </div> */}

          <div className="w-72">
            <label htmlFor="mobile-no" className="block text-xl font-medium leading-6 text-gray-900">Mobile No</label>
            <div className="mt-2">
              <input type="number" name="mobile" id="mobile" autoComplete="given-name" placeholder="7894561235"value={mobile} onChange={(e) => setmobile(e.target.value)} className="scroll-hidden block w-full pl-4 rounded-md border-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="address" className="block text-xl font-medium leading-6 text-gray-900">address</label>
            <div className="mt-2">
              <input type="text" name="address" placeholder="address" id="address" autoComplete="given-name"value={address} onChange={(e) => setaddress(e.target.value)} className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="country" className="block text-xl font-medium leading-6 text-gray-900">Country/residency</label>
            <div className="mt-2">
              <input type="text" name="country" placeholder="india" id="country" autoComplete="given-name" value={country} onChange={(e) => setcountry(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="city" className="block text-xl font-medium leading-6 text-gray-900">city</label>
            <div className="mt-2">
              <input type="text" name="city" placeholder="city" id="city" autoComplete="given-name" value={city} onChange={(e) => setcity(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72 ">
            <label htmlFor="state" className="block text-xl font-medium leading-6 text-gray-900">State</label>
            <div className="mt-2">
              <input type="text" name="state" placeholder="Himachal pradesh" id="state" autoComplete="given-name" value={state} onChange={(e) => setstate(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="zipcode" className="block text-xl font-medium leading-6 text-gray-900">zip code</label>
            <div className="mt-2">
              <input type="number" name="zip" placeholder="147303" id="zip" autoComplete="given-name" value={zip} onChange={(e) => setzip(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
            
          </div>
          <div className="w-72">
            <label htmlFor="gender" className="block text-xl font-medium leading-6 text-gray-900">Gender</label>
            <div className="mt-2">
              <input type="text" name="gender" placeholder="male/female" id="gender" autoComplete="given-name" value={gender} onChange={(e) => setgender(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
            
          </div>


          <div className="w-full ml-14 mr-14 ">
            <p className="text-xl font-bold">Work details-</p>
          </div>

          <div className="w-72">
            <label htmlFor="company-name" className="block text-xl font-medium leading-6 text-gray-900">Company Name</label>
            <div className="mt-2">
              <input type="text" name="company_name" placeholder="Company Name" id="company_name" autoComplete="given-name" value={company_name} onChange={(e) => setcompany_name(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-72">
            <label htmlFor="designation" className="block text-xl font-medium leading-6 text-gray-900">Designation</label>
            <div className="mt-2">
              <input type="text" name="company_designation" placeholder="eg. software developer" id="company_designation" value={company_designation} onChange={(e) => setcompany_designation(e.target.value)}autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-72">
            <label htmlFor="salary" className="block text-xl font-medium leading-6 text-gray-900">Salary</label>
            <div className="mt-2">
              <input type="text" name="salary" placeholder="₹ 7,00,000.00" id="salary" autoComplete="given-name" value={salary} onChange={(e) => setsalary(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-72">
            <label htmlFor="company-name" className="block text-xl font-medium leading-6 text-gray-900">Company Address</label>
            <div className="mt-2">
              <input type="text" name="company_location" placeholder="eg. software developer" id="company_location" autoComplete="given-name" value={company_location} onChange={(e) => setcompany_location(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
{/* 
          <div className="w-72">
            <label htmlFor="notice-period" className="block text-xl font-medium leading-6 text-gray-900">
              Notice period
            </label>
            <div className="mt-2">
              <input type="text" name="notice_period" id="notice_period" placeholder="eg: 15 days" className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div> */}


          {/* <div className="w-72 flex items-center">
            <input type="checkbox" name="current_student" id="current_student" className="mr-2" onChange={handleCheckboxChange}/>
            <label htmlFor="current_student" className="block text-xl font-medium leading-6 text-gray-900">
              Current Employee
            </label>
          </div> */}

          <div className="w-72">
            <label htmlFor="start_date" className="block text-xl font-medium leading-6 text-gray-900">
              Start Date
            </label>
            <div className="mt-2">
              <input type="date"  name="start_date" id="start_date" placeholder="select date" value={start_date} onChange={(e) => setstart_date(e.target.value)}className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            </div>
          </div>

          <div className="w-72">
            <label htmlFor="end_date" className="block text-xl font-medium leading-6 text-gray-900">
              End Date
            </label>
            <div className="mt-2">
              <input type="date" name="end_date" id="end_date" placeholder="select date" value={end_date} onChange={(e) => setend_date(e.target.value)}className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled={currentStudent}/>
            </div>
          </div>


          {/* education--------------------------- */}

          <div className="w-full ml-14 mr-14 ">
            <p className="text-xl font-bold">Education-</p>
          </div>

          <div className="w-72">
            <label htmlFor="institute" className="block text-xl font-medium leading-6 text-gray-900">Institute Name</label>
            <div className="mt-2">
              <input type="text" name="education_institute" placeholder="institute name" id="education_institute" autoComplete="given-name" value={education_institute} onChange={(e) => seteducation_institute(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-72">
            <label htmlFor="degree" className="block text-xl font-medium leading-6 text-gray-900">Degree</label>
            <div className="mt-2">
              <input type="text" name="education_degree" placeholder="eg:undergreduate" id="education_degree" autoComplete="given-name" value={education_degree} onChange={(e) => seteducation_degree(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          <div className="w-72">
            <label htmlFor="specialization" className="block text-xl font-medium leading-6 text-gray-900">Specialization</label>
            <div className="mt-2">
              <input type="text" name="major" placeholder="eg: computer science" id="major" autoComplete="given-name" value={major} onChange={(e) => setmajor(e.target.value)}className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>

          {/* <div className="w-72 flex items-center">
            <input type="checkbox" name="current_student" id="current_student" className="mr-2" onChange={handleCheckboxChange}/>
            <label htmlFor="current_student" className="block text-xl font-medium leading-6 text-gray-900">
              Current Student
            </label>
          </div>  */}

          <div className="w-72">
            <label htmlFor="duration" className="block text-xl font-medium leading-6 text-gray-900">
              Degree Duration
            </label>
            <div className="mt-2">
              <input type="text"  name="degree_duration" id="degree_duration" placeholder=" years"value={degree_duration} onChange={(e) => setdegree_duration(e.target.value)}className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="resume" className="block text-xl font-medium leading-6 text-gray-900">
              resume
            </label>
            <div className="mt-2">
              <input type="text"  name="resume_path" id="resume_path" placeholder="write skills"value={resume_path} onChange={(e) => setresume_path(e.target.value)}className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
            </div>
          </div>
          {/* <div className="w-72">
            <label htmlFor="end_date" className="block text-xl font-medium leading-6 text-gray-900">
              End Date
            </label>
            <div className="mt-2">
              <input type="date" name="end_date" id="end_date" placeholder="select date" className="pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" disabled={currentStudent}/>
            </div>
          </div> */}


{/* 
          <div className="w-full ml-14 mr-14 ">
            <p className="text-xl font-bold">Social Links-</p>
          </div>

          <div className="w-72">
            <label htmlFor="linkedin" className="block text-xl font-medium leading-6 text-gray-900">LinkedIn</label>
            <div className="mt-2">
              <input type="text" name="linkedin" placeholder="linkedin/linkedin4093" id="linkedin" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="x" className="block text-xl font-medium leading-6 text-gray-900">X</label>
            <div className="mt-2">
              <input type="text" name="x_link" placeholder="x/linkedin4093" id="linkedin" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="instagram" className="block text-xl font-medium leading-6 text-gray-900">Instagram</label>
            <div className="mt-2">
              <input type="text" name="instagram" placeholder="/linkedin4093" id="linkedin" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="leetcode" className="block text-xl font-medium leading-6 text-gray-900">LeetCode</label>
            <div className="mt-2">
              <input type="text" name="leetcode" placeholder="leetcode/linkedin4093" id="leetcode" autoComplete="given-name" className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div> */}


          <div className="w-full flex justify-center ">
            <button type="submit" className="text-xl w-40 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-md" >Update</button>
          </div>

        </form>

      {/* ) : (
        <h1>User profile does not exist. please login again</h1>
      )} */}
    </div>
  );
};

export default UpdateProfile;

// import axios from "axios";
// import React, { useState, useEffect } from "react";
// // import { auth } from "../Firebase";
// // import { onAuthStateChanged } from "firebase/auth";

// const UserProfile = () => {
//   const [user, setUser] = useState(null);
//   const [userId, setUserId] = useState(null);
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");

//   useEffect(() => {
//     const userIdFromStorage = localStorage.getItem('userId');
//     setUserId(userIdFromStorage);
  
//     // const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//     //   setUser(currentUser);

//     //   if (currentUser) {
//     //     const profile = currentUser.providerData[0];
//     //     const displayName = profile.displayName;

//     //     if (displayName) {
//     //       console.log(user);
//     //       const nameParts = displayName.split(" ");
//     //        setFirstName(nameParts[0]);
//     //       setLastName(nameParts[1] || ""); // In case user only has one name
//     //     }
//     //   }

//   }, []);
//   useEffect(() => {
//     axios.get(`http://localhost:8081/users/${userId}`)
//         .then(res => {
//             setUser(res.data);
//             console.log("user data", res.data,user);
//             console.log("user",user.city)
//         })
//         .catch(error => {
//             console.error("Error fetching user data:", error);
//         });
// }, []);




//   return (
//     <div className="w-full shadow-sm border-2 bg-gray-100 rounded-md mt-24 mb-4">
//       <p className="text-center text-2xl mt-4 underline font-extrabold font-sarif">Profile details</p>

//           <div className=" w-full h-max mb-8  flex justify-center items-center flex-wrap gap-6 mt-2">
//           <div className="w-full ml-14 mr-14 ">
//             <p className="text-xl font-bold">Information-</p>
//             </div>
//             <div className="w-72">
//               <label htmlFor="first-name" className="block text-xl font-medium leading-6 text-gray-900">First Name</label>
//               <div className="mt-2">
//                 <input type="text" name="first_name" id="first_name" autoComplete="given-name" value={user.f_name ? user.f_name:"null"} disabled className=" block w-full rounded-md border-2 border-2lack py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " />
//               </div>
//             </div>
            
//             <div className="w-72">
//               <label htmlFor="middle-name" className="block text-xl font-medium leading-6 text-gray-900">Middle Name</label>
//               <div className="mt-2">
//                   <input type="text" name="middle_name" id="middleName" autoComplete="given-name" value={userId} disabled className=" block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " />
//               </div>
//             </div>
//             <div className="w-72">
//               <label htmlFor="last-name" className="block text-xl font-medium leading-6 text-gray-900">Last Name</label>
//               <div className="mt-2">
//                   <input type="text" name="last_name" id="lastName" autoComplete="given-name" value={userId} disabled className=" block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " />
//               </div>
//             </div>

//             <div className="w-72">
//               <label htmlFor="email" className="block text-xl font-medium leading-6 text-gray-900">Email Address</label>
//               <div className="mt-2">
//                 <input type="email" name="email" id="email" autoComplete="given-name" value={userId} disabled className=" block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 " />
//               </div>
//             </div>

//             <div className="w-72">
//               <label htmlFor="mobile-no" className="block text-xl font-medium leading-6 text-gray-900">Mobile No</label>
//               <div className="mt-2">
//                 <input type="number" name="email" id="email" autoComplete="given-name"  value={userId} disabled className="scroll-hidden block w-full pl-4 rounded-md border-2 py-1.5 text-black shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
//             <div className="w-72">
//               <label htmlFor="address" className="block text-xl font-medium leading-6 text-gray-900">address</label>
//               <div className="mt-2">
//                 <input type="text" name="address" placeholder="address" id="email" autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
//             <div className="w-72">
//               <label htmlFor="country" className="block text-xl font-medium leading-6 text-gray-900">Country</label>
//               <div className="mt-2">
//                 <input type="text" name="country" placeholder="india" id="email" autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
//             <div className="w-72">
//               <label htmlFor="city" className="block text-xl font-medium leading-6 text-gray-900">city</label>
//               <div className="mt-2">
//                 <input type="text" name="city" placeholder="city" id="email" autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
//             <div className="w-72 ">
//               <label htmlFor="state" className="block text-xl font-medium leading-6 text-gray-900">State</label>
//               <div className="mt-2">
//                 <input type="text" name="state" placeholder="Himachal pradesh" id="email" autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
//             <div className="w-72">
//               <label htmlFor="zipcode" className="block text-xl font-medium leading-6 text-gray-900">zip code</label>
//               <div className="mt-2">
//                 <input type="number" name="zipcode" placeholder="147303" id="zipcode" autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
           
            
//             <div className="w-full ml-14 mr-14 ">
//             <p className="text-xl font-bold">Work details-</p>
//             </div>

//             <div className="w-72">
//               <label htmlFor="company-name" className="block text-xl font-medium leading-6 text-gray-900">Company Name</label>
//               <div className="mt-2">
//                 <input type="text" name="company_name" placeholder="Company Name" id="email" autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>

//             <div className="w-72">
//               <label htmlFor="disignation" className="block text-xl font-medium leading-6 text-gray-900">Designation</label>
//               <div className="mt-2">
//                 <input type="text" name="designation" placeholder="eg. software developer" id="email" autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>

//             <div className="w-72">
//               <label htmlFor="salary" className="block text-xl font-medium leading-6 text-gray-900">Salary</label>
//               <div className="mt-2">
//                 <input type="text" name="salary" placeholder="₹ 7,00,000.00" id="email" autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>

//             <div className="w-72">
//               <label htmlFor="company-address" className="block text-xl font-medium leading-6 text-gray-900">Company Address</label>
//               <div className="mt-2">
//                 <input type="text" name="designation" placeholder="eg. software developer" id="email" autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>

// {/* education------------------------------------------------------------------------- */}
//             <div className="w-full ml-14 mr-14 ">
//             <p className="text-xl font-bold">Education-</p>
//             </div>

//             <div className="w-72">
//               <label htmlFor="institude-name" className="block text-xl font-medium leading-6 text-gray-900">Institute Name</label>
//               <div className="mt-2">
//                 <input type="text" name="institute_name" placeholder="institute name" id="institute" disabled autoComplete="given-name"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
            
//             <div className="w-72">
//               <label htmlFor="degree-name" className="block text-xl font-medium leading-6 text-gray-900">Degree</label>
//               <div className="mt-2">
//                 <input type="text" name="degree" placeholder="eg:undergreduate" id="institute" disabled autoComplete="given-name"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>

//             <div className="w-72">
//               <label htmlFor="specialization" className="block text-xl font-medium leading-6 text-gray-900">Specialization</label>
//               <div className="mt-2">
//                 <input type="text" name="specialization" placeholder="eg: computer science" id="specialization" disabled autoComplete="given-name"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>

//             <div className="w-72 flex items-center">
//             {/* Checkbox input inline with label */}
//             <input type="checkbox" name="current_student" id="current_student" disabled className="mr-2" />
//             <label htmlFor="current_student" className="block text-xl font-medium leading-6 text-gray-900">Current Student</label>
//           </div>

//             <div className="w-72">
//               <label htmlFor="start-date" className="block text-xl font-medium leading-6 text-gray-900">Start Date</label>
//               <div className="mt-2">
//                 <input type="date" name="start_date" disabled placeholder="select date" id="start_date"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>

//             <div className="w-72">
//               <label htmlFor="end-date" className="block text-xl font-medium leading-6 text-gray-900">End Date</label>
//               <div className="mt-2">
//                 <input type="date" name="end_date" disabled placeholder="select date" id="end_date"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>



// {/* social---------------------------------------------------------------------------- */}
//             <div className="w-full ml-14 mr-14 ">
//             <p className="text-xl font-bold">Social Links-</p>
//             </div>

//             <div className="w-72">
//               <label htmlFor="linkedin" className="block text-xl font-medium leading-6 text-gray-900">LinkedIn</label>
//               <div className="mt-2">
//                 <input type="text" name="linkedin" placeholder="linkedin/linkedin4093" id="linkedin" autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
//             <div className="w-72">
//               <label htmlFor="x" className="block text-xl font-medium leading-6 text-gray-900">X</label>
//               <div className="mt-2">
//                 <input type="text" name="x_link" placeholder="x/linkedin4093" id="x" autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
//             <div className="w-72">
//               <label htmlFor="instagram" className="block text-xl font-medium leading-6 text-gray-900">Instagram</label>
//               <div className="mt-2">
//                 <input type="text" name="instagram" placeholder="/linkedin4093" id="instagram" autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>
//             <div className="w-72">
//               <label htmlFor="leetcode" className="block text-xl font-medium leading-6 text-gray-900">LeetCode</label>
//               <div className="mt-2">
//                 <input type="text" name="leetcode" placeholder="leetcode/linkedin4093" id="leetcode" autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
//               </div>
//             </div>




//           </div>

    
//     </div>
//   );
// };

// export default UserProfile;


import React, { useState, useEffect } from "react";
import axios from "../axios";

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const userIdFromStorage = localStorage.getItem('userId');
    if (userIdFromStorage) {
      setUserId(userIdFromStorage);
    } else {
      setError("User ID not found");
    }
  }, []);

  useEffect(() => {
    if (!userId) return;

    axios.get(`/user/${userId}`)
      .then(res => {
        setUser(res.data);
        setLoading(false);
      })
      .catch(error => {
        setError("Error fetching user data");
        setLoading(false);
      });
  }, [userId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full shadow-md border-2 bg-white rounded-md mt-24 mb-4">
      <p className="text-center text-2xl mt-4 underline font-extrabold font-serif">Profile details</p>

      {user && (
        <div className="w-full h-max mb-8 flex justify-center items-center flex-wrap gap-6 mt-2">
          <div className="w-full ml-14 mr-14 ">
            <p className="text-xl font-bold">Information-</p>
          </div>
          <div className="w-72">
            <label htmlFor="first-name" className="block text-xl font-medium leading-6 text-gray-900">{user.f_name}</label>
            <div className="mt-2">
              <input type="text" name="first_name" id="first_name" autoComplete="given-name" value={user.f_name || ''} disabled className="block w-full rounded-md border-2 border-2lack py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="middle-name" className="block text-xl font-medium leading-6 text-gray-900">Middle Name</label>
            <div className="mt-2">
              <input type="text" name="middle_name" id="middleName" autoComplete="given-name" value={user.s_name || ''} disabled className="block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="last-name" className="block text-xl font-medium leading-6 text-gray-900">Last Name</label>
            <div className="mt-2">
              <input type="text" name="last_name" id="lastName" autoComplete="given-name" value={user.l_name || ''} disabled className="block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="email" className="block text-xl font-medium leading-6 text-gray-900">Email Address</label>
            <div className="mt-2">
              <input type="email" name="email" id="email" autoComplete="email" value={user.email || ''} disabled className="block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          <div className="w-72">
            <label htmlFor="mobile-no" className="block text-xl font-medium leading-6 text-gray-900">Mobile No</label>
            <div className="mt-2">
              <input type="tel" name="mobile_no" id="mobileNo" autoComplete="tel" value={user.mobile || ''} disabled className="block w-full rounded-md border-2 py-1.5 pl-4 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            </div>
          </div>
          {/* Add other input fields here */}
          <div className="w-72">
              <label htmlFor="address" className="block text-xl font-medium leading-6 text-gray-900">address</label>
              <div className="mt-2">
                <input type="text" name="address" placeholder="address" id="address"  value={ user.address||" "} autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="country" className="block text-xl font-medium leading-6 text-gray-900">City</label>
              <div className="mt-2">
                <input type="text" name="city" placeholder="india" id="city" value={ user.city ||" "} autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="city" className="block text-xl font-medium leading-6 text-gray-900">State</label>
              <div className="mt-2">
                <input type="text" name="state" placeholder="city" id="state" value={user.state ||" "} autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72 ">
              <label htmlFor="country" className="block text-xl font-medium leading-6 text-gray-900">Country</label>
              <div className="mt-2">
                <input type="text" name="county" placeholder="Himachal pradesh" id="country" value={ user.country ||" "} autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="zipcode" className="block text-xl font-medium leading-6 text-gray-900">zip code</label>
              <div className="mt-2">
                <input type="number" name="zipcode" placeholder="147303" id="zipcode" value={user.zip ||" "} autoComplete="given-name" disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
        
         
            <div className="w-full ml-14 mr-14 ">
            <p className="text-xl font-bold">Work details-</p>
            </div>
            <div className="w-72">
              <label htmlFor="company-name" className="block text-xl font-medium leading-6 text-gray-900">Company Name</label>
              <div className="mt-2">
                <input type="text" name="company_name" placeholder="Company Name" id="email" value={user.company_name ||" "} autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="disignation" className="block text-xl font-medium leading-6 text-gray-900">Designation</label>
              <div className="mt-2">
                <input type="text" name="designation" placeholder="eg. software developer" id="email" value={user.company_designation ||" "} autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="salary" className="block text-xl font-medium leading-6 text-gray-900">Salary</label>
              <div className="mt-2">
                <input type="text" name="salary" placeholder="₹ 7,00,000.00" id="email" value={ user.salary ||" "} autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="company-address" className="block text-xl font-medium leading-6 text-gray-900">Company Address</label>
              <div className="mt-2">
                <input type="text" name="designation" placeholder="eg. software developer" id="email" value={ user.company_location ||" "} autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>

{/* education------------------------------------------------------------------------- */}
            <div className="w-full ml-14 mr-14 ">
            <p className="text-xl font-bold">Education-</p>
            </div>
            <div className="w-72">
              <label htmlFor="institude-name" className="block text-xl font-medium leading-6 text-gray-900">Institute Name</label>
              <div className="mt-2">
                <input type="text" name="institute_name" placeholder="institute name" id="institute_name" disabled value={user.intitute ||" "} autoComplete="given-name"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
         
            <div className="w-72">
              <label htmlFor="degree-name" className="block text-xl font-medium leading-6 text-gray-900">Degree</label>
              <div className="mt-2">
                <input type="text" name="degree" placeholder="eg:undergreduate" id="institute" disabled value={ user.degree||" "} autoComplete="given-name"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="specialization" className="block text-xl font-medium leading-6 text-gray-900">Specialization</label>
              <div className="mt-2">
                <input type="text" name="specialization" placeholder="eg: computer science" id="specialization" disabled value={user.specialization ||" "} autoComplete="given-name"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72 flex items-center">
            {/* Checkbox input inline with label */}
            {/* <input type="checkbox" name="current_student" id="current_student" disabled className="mr-2" />
            <label htmlFor="current_student" className="block text-xl font-medium leading-6 text-gray-900">Current Student</label>
          </div>
            <div className="w-72">
              <label htmlFor="duration" className="block text-xl font-medium leading-6 text-gray-900">Degree Duration</label>
              <div className="mt-2">
                <input type="text" name="duration" value={user.degree_duration|| " "} disabled placeholder="select date" id="start_date"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div> */}
            </div>
            {/* <div className="w-72">
              <label htmlFor="end-date" className="block text-xl font-medium leading-6 text-gray-900">End Date</label>
              <div className="mt-2">
                <input type="date" name="end_date" value={user.endDate || " "} disabled placeholder="select date" id="end_date"   className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> */}



 {/* social---------------------------------------------------------------------------- */}
            {/* <div className="w-full ml-14 mr-14 ">
            <p className="text-xl font-bold">Social Links-</p>
            </div>

            <div className="w-72">
              <label htmlFor="linkedin" className="block text-xl font-medium leading-6 text-gray-900">LinkedIn</label>
              <div className="mt-2">
                <input type="text" name="linkedin" placeholder="linkedin/linkedin4093" id="linkedin" value={ user.linkedin||" "} autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="x" className="block text-xl font-medium leading-6 text-gray-900">X</label>
              <div className="mt-2">
                <input type="text" name="x_link" placeholder="x/linkedin4093" id="x" value={user.x ||" "} autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="instagram" className="block text-xl font-medium leading-6 text-gray-900">Instagram</label>
              <div className="mt-2">
                <input type="text" name="instagram" placeholder="/linkedin4093" id="instagram" value={ user.instagram||" "} autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div>
            <div className="w-72">
              <label htmlFor="leetcode" className="block text-xl font-medium leading-6 text-gray-900">LeetCode</label>
              <div className="mt-2">
                <input type="text" name="leetcode" placeholder="leetcode/linkedin4093" id="leetcode" value={user.leetcode ||" "} autoComplete="given-name"  disabled className=" pl-4 block w-full rounded-md border-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-black placeholder:text-black focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              </div>
            </div> */}
        </div>
      )}
    </div>
  );
};

export default UserProfile;

import React, { useState } from "react";
import CircleNotifications from "@mui/icons-material/CircleNotifications";


const Notification = () => {
  const [isOpen, setIsOpen] = useState(false);

  

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <div onClick={toggleMenu}>
     <CircleNotifications color='primary'  sx={{ fontSize: 48 }}/>
      </div>
      <div className={`absolute bg-white top-16 right-0 w-64   p-4 border border-gray-300 shadow-md rounded-md z-50 transition-all duration-500 ease-in-out ${isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
       <p className="text-xs">  there is no notification </p>

      </div>
    </div>
  );
}

export default Notification;

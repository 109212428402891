import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Profilee from '../images/Profilee.png';
import "../components/assests/Loader.css";

const MyJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
            fetchJobs(storedUserId);
        } else {
            navigate("/login"); // Redirect to login if user ID is not found
        }
    }, [navigate]);

    const fetchJobs = async (userId) => {
        try {
            const response = await axios.get(`http://localhost:8081/jobs/getJobsByUserId/${userId}`);
            setJobs(response.data.slice(0, 10));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setLoading(false);
        }
    };

    const logoutHandler = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/auth/logout');
            setUserId(null);
            localStorage.clear();
            navigate("/");
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <div className="w-full flex flex-col">
            <Header />
            <section className="section-5 bg-2 pt-24">
                <div className="container py-5">
                    <div className="row">
                        {/* Sidebar */}
                        <div className="col-lg-3">
                            {/* Profile Card */}
                            <div className="card border shadow mb-4 p-3 w-full">
                                {/* Profile Info */}
                                <div className="s-body text-center mt-3">
                                    <div className="w-full flex justify-center items-center">
                                        <img src={Profilee} alt="avatar" className="rounded-circle img-fluid" style={{ width: 150 }} />
                                    </div>
                                    <h5 className="mt-3 pb-0">Recruiter</h5>
                                    <p className="text-muted mb-1 fs-6">HR</p>
                                    <div className="d-flex justify-content-center mb-2">
                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" className="btn btn-primary">Change Profile Picture</button>
                                    </div>
                                </div>
                            </div>
                            {/* Account Navigation */}
                            <div className="card account-nav border rounded-lg shadow mb-4 mb-lg-0 w-full">
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between p-3">
                                            <Link to="">Account Setting</Link>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between p-3">
                                            <Link to="/recruiter">Post Job</Link>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between p-3">
                                            <Link to="/myjobs">My Jobs</Link>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <Link onClick={logoutHandler}>Logout</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Main Content */}
                        <div className="col-lg-9">
                            <div className="card border shadow mb-4 p-3">
                                <div className="card-body card-form">
                                    <div className="flex justify-between">
                                        <div>
                                            <h3 className="text-lg font-semibold mb-1">My Jobs</h3>
                                        </div>
                                    </div>
                                    {loading ? (
                                        <div id="container" className="text-center my-4">
                                            <span className="loading-circle sp1">
                                                <span className="loading-circle sp2">
                                                    <span className="loading-circle sp3"></span>
                                                </span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="bg-gray-100">
                                                    <tr className="text-xl font-bold">
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Company</th>
                                                        <th scope="col">Job Created</th>
                                                        <th scope="col">Applicants</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {jobs.map((job, index) => (
                                                        <tr key={job.id} className="bg-gray-50">
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <div className="font-semibold">{job.title}</div>
                                                                <div className="text-sm">{job.category} . {job.job_nature}</div>
                                                            </td>
                                                            <td>
                                                                <div className="font-semibold">{job.company_name}</div>
                                                                <div className="text-sm">{job.location}</div>
                                                            </td>
                                                            <td>{new Date(job.createdAt).toLocaleDateString()}</td>
                                                            <td>{job.applications} Applications</td>
                                                            <td>
                                                                <div className="text-capitalize">{job.status}</div>
                                                            </td>
                                                            <td>
                                                                <div className="float-right">
                                                                    <Link to="" className="dropdown-toggle">
                                                                        <i className="fas fa-ellipsis-v"></i>
                                                                    </Link>
                                                                    <ul className="dropdown-menu">
                                                                        <li><Link to="" className="dropdown-item"><i className="fas fa-eye"></i> View</Link></li>
                                                                        <li><Link to="" className="dropdown-item"><i className="fas fa-edit"></i> Edit</Link></li>
                                                                        <li><Link to="" className="dropdown-item"><i className="fas fa-trash"></i> Remove</Link></li>
                                                                    </ul>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                                <div className="mt-3">
                                    <button className="btn btn-primary" onClick={() => navigate('/alljobs')}>View All Jobs</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Modal */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Profile Picture</h5>
                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">Profile Image</label>
                                    <input type="file" className="form-control" id="image" name="image" />
                                </div>
                                <div className="flex justify-end">
                                    <button type="submit" className="btn btn-primary mx-3">Update</button>
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MyJobs;

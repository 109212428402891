import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from '../axios';
import Header from '../components/Header';
import Footer from '../components/Footer';
import "../components/assests/Loader.css";

const Applications = () => {
    const { jobId } = useParams();
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchApplications(jobId);
    }, [jobId]);

    const fetchApplications = async (jobId) => {
        try {
            const response = await axios.get(`http://localhost:8081/applications/${jobId}`);
            setApplications(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching applications:', error);
            setLoading(false);
        }
    };

    return (
        <div className="w-full flex flex-col">
            <Header />
            <section className="section-5 bg-2 pt-24">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card border shadow mb-4 p-3">
                                <div className="card-body card-form">
                                    <div className="flex justify-between">
                                        <div>
                                            <h3 className="text-lg font-semibold mb-1">Applications for Job ID: {jobId}</h3>
                                        </div>
                                    </div>
                                    {loading ? (
                                        <div id="container" className="text-center my-4">
                                            <span className="loading-circle sp1">
                                                <span className="loading-circle sp2">
                                                    <span className="loading-circle sp3"></span>
                                                </span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="bg-gray-100">
                                                    <tr className="text-xl font-bold">
                                                        <th scope="col">S.No.</th>
                                                        <th scope="col">Applicant Name</th>
                                                        <th scope="col">Email</th>
                                                        <th scope="col">Resume</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {applications.map((application, index) => (
                                                        <tr key={application.id} className="bg-gray-50">
                                                            <td>{index + 1}</td>
                                                            <td>{application.name}</td>
                                                            <td>{application.email}</td>
                                                            <td>
                                                                <a href={application.resumeUrl} target="_blank" rel="noopener noreferrer">View Resume</a>
                                                            </td>
                                                            <td>{application.status}</td>
                                                            <td>
                                                                <button className="btn btn-primary">Update Status</button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                                <div className="mt-3">
                                    <Link to="/myjobs" className="btn btn-primary">Back to My Jobs</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Applications;

// import React, { useState, useEffect } from "react";
// import { UserAuth } from "../Context/AuthContext";
// import { useNavigate, Link } from "react-router-dom";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import job360 from '../images/job360.png'
// import axios from "axios";

// const Signin = () => {
//   const { googleSignIn, user } = UserAuth();
//   const navigate = useNavigate();
//   const [userData, setUserData] = useState({ email: "", password: "" });
//   const [notice, setNotice] = useState("");

//   const handleData = (e) => {
//     setUserData({ ...userData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:8081/auth/login', userData);
//       if (response && response.data) {
//         const { token, id } = response.data;
//         localStorage.setItem('token', token);
//         localStorage.setItem('userId', id);
//         localStorage.setItem('email', userData.email);
//         alert('Logged in successfully');
//         setNotice("successfully Loggedin")
//         navigate("/userprofile");
//       } else {
//         console.error('Login error: Response data is missing');
//         setNotice("Login failed. Please try again later.");
//       }
//     } catch (error) {
//       console.error('Login error:', error);
//       setNotice("Login failed. Please try again later.");
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     try {
//       await googleSignIn();
//       navigate("/userprofile");
//     } catch (error) {
//       console.log(error);
//       setNotice("Google sign-in failed. Please try again later.");
//     }
//   };

//   useEffect(() => {
//     // Check if the user is signed in
//     if (user && user.uid) {
//       navigate("/userprofile");
//     }
//   }, [user, navigate]);

//   return (
//     <div className="w-full h-full flex flex-col">
//       <Header />
//       <div className=" mt-28 mb-0" id="login">
//         {notice && (
//           <div className="alert alert-warning flex flex-wrap justify-between" role="alert">
//             {notice}
//             <button
//               onClick={() => setNotice("")}
//               className="text-white ml-2 bg-red-500 hover:bg-red-600 px-2 py-1 rounded-lg focus:outline-none"
//             >
//               Close
//             </button>
//           </div>
//         )}
//         <div className="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow border shadow-slate-300">
//           <div className="flex justify-center items-center"><img src={job360} alt="changes" className="w-max h-20 object-cover" /></div>
//           <h1 className="text-4xl font-medium">Login</h1>
//           <p className="text-slate-500">Hi, Welcome back 👋</p>
//           <div className="my-4">
//             <button className="w-full text-center py-2 my-0 border flex space-x-2 items-center justify-center border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150" onClick={handleGoogleSignIn}>
//               <img src="https://www.svgrepo.com/show/355037/google.svg" className="w-6 h-6" alt="sdfjafkl" /> <span>Login with Google</span>
//             </button>
//           </div>
//           <form onSubmit={handleSubmit} action="" className="">
//             <div className="flex flex-col space-y-3">
//               <label htmlFor="email">
//                 <p className="font-medium text-slate-700 mb-0 ">Email address*</p>
//                 <input id="email" name="email" type="email" onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter email address" />
//               </label>
//               <label htmlFor="password">
//                 <p className="font-medium text-slate-700  mb-0">Password*</p>
//                 <input id="password" name="password" type="password" onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter your password" />
//               </label>
//               <div className="flex flex-row justify-between mt-6">
//                 <div>
//                   <label htmlFor="remember" className="">
//                     <input type="checkbox" id="remember" className="w-4 h-4 border-slate-200 focus:bg-indigo-600" />
//                     Remember me
//                   </label>
//                 </div>
//                 <div>
//                   <a href="/" className="font-medium text-indigo-600">Forgot Password?</a>
//                 </div>
//               </div>
//               <button className="w-full py-2 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center">
//                 <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
//                   <path strokeLinecap="round" strokeLinejoin="round" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
//                 </svg>
//                 <span>Login</span>
//               </button>
//               <p className="text-center">Not registered yet? <a href="/" className="text-indigo-600 font-medium inline-flex space-x-1 items-center"><span><Link to="/signup" className="text-indigo-600">Register now </Link></span><span><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
//                 <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
//               </svg></span></a></p>
//             </div>
//           </form>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Signin;

import React, { useState } from "react";
// import { UserAuth } from "../Context/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import job360 from '../images/job360.png'
import axios from "../axios";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import toast from "react-hot-toast";

const Signin = () => {
  // const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ email: "", password: "" });
  // const [notice, setNotice] = useState("");
  const [showPassword, setShowPassword] = useState(false); 

  const handleData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8081/auth/login', userData);
      if (response && response.data) {
        const { token, id, role } = response.data;
        localStorage.setItem('token', token);
        localStorage.setItem('userId', id);
        localStorage.setItem('email', userData.email);
        localStorage.setItem('role', role);

        toast.success("Logged in successfully")
        // alert('Logged in successfully');
        console.log("data", response.data,"role", role,"respounse", response)
        if (role === 'admin') {
          navigate("/recruiter")
        } else if (role === 'user') {
          navigate("/userprofile");
        }
        else {
          navigate('*')
        }
      } else {
        toast.error("Login Failed! Please try again MISSING")
        console.error('Login error: Response data is missing');
        // setNotice(`Login failed. Please try again later. {error.message}`);

      }
    } catch (error) {
      toast.error(`Login Failed! Please try again ${error.message}`)
      console.error('Login error:', error.message);
      // alert("login failed",error.message);
      // setNotice("Login failed. Please try again later.");
    }
  };

  // const handleGoogleSignIn = async () => {
  //   try {
  //     await googleSignIn();
  //     navigate("/userprofile");
  //   } catch (error) {
  //     console.log(error);

  //     setNotice("Google sign-in failed. Please try again later.");
  //   }
  // };

  // useEffect(() => {
  //   // Check if the user is signed in
  //   if (user && user.uid) {
  //     navigate("/userprofile");
  //   }
  // }, [user, navigate]);

  return (
    <div className="w-full h-full flex flex-col">
      <Header />
      <div className=" mt-28" id="login">
        {/* {notice && (
          <div className="max-w-lg alert alert-warning flex flex-wrap justify-between" role="alert">
            {notice}
            <button
              onClick={() => setNotice("")}
              className="text-white ml-2 bg-red-500 hover:bg-red-600 px-2 py-1 rounded-lg focus:outline-none"
            >
              Close
            </button>
          </div> 
        )}*/}
        <div className="max-w-lg mx-auto my-10 bg-white p-8 rounded-xl shadow border shadow-slate-300">
          <div className="flex justify-center items-center"><img src={job360} alt="changes" className="w-max h-20 object-cover" /></div>
          <h1 className="text-4xl font-medium">Login</h1>
          <p className="text-slate-500">Hi, Welcome back 👋</p>
          <div className="my-4">
            <button className="w-full text-center py-2 my-0 border flex space-x-2 items-center justify-center border-slate-200 rounded-lg text-slate-700 hover:border-slate-400 hover:text-slate-900 hover:shadow transition duration-150" >
              <img src="https://www.svgrepo.com/show/355037/google.svg" className="w-6 h-6" alt="sdfjafkl" /> <span>Login with Google</span>
            </button>
          </div>
          <form onSubmit={handleSubmit} action="" className="">
            <div className="flex flex-col space-y-3">
              <label htmlFor="email">
                <p className="font-medium text-slate-700 mb-0 ">Email address*</p>
                <input id="email" name="email" type="email" onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter email address" />
              </label>
              <label htmlFor="password">
                <p className="font-medium text-slate-700  mb-0">Password*</p>
                <div className="relative">
                  <input id="password" name="password" type={showPassword ? "text" : "password"} onChange={handleData} className="w-full py-2 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow" placeholder="Enter your password" />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                  >
                    {showPassword ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </span>
                </div>
              </label>
              <div className="flex flex-row justify-between mt-6">
                <div>
                  <label htmlFor="remember" className="">
                    <input type="checkbox" id="remember" className="w-4 h-4 border-slate-200 focus:bg-indigo-600" />
                    Remember me
                  </label>
                </div>
                <div>
                  <a href="/forgotpassword" className="font-medium text-indigo-600">Forgot Password?</a>
                </div>
              </div>
              <button className="w-full py-2 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                </svg>
                <span>Login</span>
              </button>
              <p className="text-center">Not registered yet? <a href="/" className="text-indigo-600 font-medium inline-flex space-x-1 items-center"><span><Link to="/signup" className="text-indigo-600">Register now </Link></span><span><svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg></span></a></p>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Signin;

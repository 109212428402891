

// import React, { useState,useEffect } from 'react';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import Profilee from '../images/Profilee.png';
// import { Link,useNavigate} from 'react-router-dom';
// import axios from 'axios';

// const Recruiter = () => {
//     const [user,setUser] = useState();
//     const navigate= useNavigate();
//     const [jobData, setJobData] = useState({
//         title: '',
//         category: '',
//         job_nature: 'Full Time',
//         vacancy: '',
//         salary: '',
//         location: '',
//         description: '',
//         benefits: '',
//         responsibility: '',
//         qualifications: '',
//         keywords: '',
//         company_name: '',
//         company_location: '',
//         website: '',
//         job_logo: '',
//         deleted: false
//     });

//     const handleChange = (e) => {

//         setJobData({ ...jobData, [e.target.name]: e.target.value });
//         alert("form added");
//         console.log(jobData.company_location)
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         try {
//             const response = await axios.post('http://localhost:8081/jobs/createjob', jobData);
//             console.log('Job added successfully:', response.data);
//             alert("working")
//             // Reset form data after successful submission
//             alert("job added");
            
//         } catch (error) {
//             alert("error");
//             console.error('Error adding job:', error);
//         }
//     };

//     useEffect(() => {
//         const storedUserId = localStorage.getItem('userId');
//         setUser(storedUserId);
//         console.log(user, "user", storedUserId, "userId");
//     }, []); 

//     const logoutHandler = async (e) => {
//         e.preventDefault();
//         try {
//             await axios.post('http://localhost:8081/auth/logout');
//             setUser(null);
//             localStorage.clear('userId','email','token')
//             console.log("logout");
//             console.log("user",user);
    
//           alert("logged out",user,"userlgout");
//           navigate("/");
//         } catch (error) {
//           console.error('Error logging out:', error);
//         }
//       };


//     return (
//         <div>
//             <Header />
//             <section className="section-5 bg-2">
//                 <div className="container py-5">
//                     <div className="row">
//                         <div className="col">
//                             <nav aria-label="breadcrumb" className="rounded-3 p-3 mb-4">
//                                 <ol className="breadcrumb mb-0">
//                                     <li className="breadcrumb-item"><a href="/">Home</a></li>
//                                     <li className="breadcrumb-item active">Post a Job</li>
//                                 </ol>
//                             </nav>
//                         </div>
//                     </div>
//                     <div className="row">
//                         <div className="col-lg-3">
//                             <div className="card border shadow mb-4 p-3 " style={{ width: "100%" }}>
//                                 <div className="s-body text-center mt-3">

//                                     <div className="w-full flex justify-center items-center ">
//                                         <img src={Profilee} alt="avatar" className="rounded-circle img-fluid" style={{ width: 150 }} />
//                                     </div>
//                                     <h5 className="mt-3 pb-0">Profile Name</h5>
//                                     <p className="text-muted mb-1 fs-6">Designation</p>
//                                     <div className="d-flex justify-content-center mb-2">
//                                         <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button"
//                                             className="btn btn-primary">Change Profile Picture</button>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="card account-nav border shadow mb-4 mb-lg-0" style={{ width: "100%" }}>
//                                 <div className="card-body p-0">
//                                     <ul className="list-group list-group-flush ">
//                                         <li className="list-group-item d-flex justify-content-between p-3">
//                                             <p >Account Settings</p>
//                                         </li>
//                                         <Link to="/recruiter" className="list-group-item d-flex justify-content-between align-items-center p-3">
//                                             <p >Post Job</p>
//                                         </Link>
//                                         <Link to="/myjobs" className="list-group-item d-flex justify-content-between align-items-center p-3">
//                                             <p >My Jobs</p>
//                                         </Link>
//                                         <p onClick={logoutHandler} className="list-group-item d-flex justify-content-between align-items-center p-3">
//                                             <p >Logout</p>
//                                         </p>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="col-lg-9">
//                             <div className="card border shadow mb-4" style={{ width: "100%" }}>
//                                 <div className="card-body card-form p-4">
//                                     <h3 className="fs-4 mb-1">Job Details</h3>
//                                     <form onSubmit={handleSubmit}>
//                                         <div className="row">
//                                             <div className="col-md-6 mb-4">
//                                                 <label htmlFor="title" className="mb-2">Title<span className="req">*</span></label>
//                                                 <input type="text" placeholder="Job Title" id="title" name="title" className="form-control" onChange={handleChange} />
//                                             </div>
//                                             <div className="col-md-6 mb-4">
//                                                 <label htmlFor="category" className="mb-2">Category<span className="req">*</span></label>
//                                                 <select name="category" id="category" className="form-control" onChange={handleChange}>
//                                                     <option value="">Select a Category</option>
//                                                     <option value="Engineering">Engineering</option>
//                                                     <option value="Accountant">Accountant</option>
//                                                     <option value="Information Technology">Information Technology</option>
//                                                     <option value="Fashion designing">Fashion designing</option>
//                                                 </select>
//                                             </div>
//                                         </div>

//                                         <div className="row">
//                                             <div className="col-md-6 mb-4">
//                                                 <label htmlFor="jobNature" className="mb-2">Job Nature<span className="req">*</span></label>
//                                                 <select name="job_nature" id="job_nature" className="form-control" onChange={handleChange}>
//                                                     <option>Full Time</option>
//                                                     <option>Part Time</option>
//                                                     <option>Remote</option>
//                                                     <option>Freelance</option>
//                                                 </select>
//                                             </div>
//                                             <div className="col-md-6 mb-4">
//                                                 <label htmlFor="vacancy" className="mb-2">Vacancy<span className="req">*</span></label>
//                                                 <input type="number" min="1" placeholder="Vacancy" id="vacancy" name="vacancy" className="form-control" onChange={handleChange} />
//                                             </div>
//                                         </div>

//                                         <div className="row">
//                                             <div className="col-md-6 mb-4">
//                                                 <label htmlFor="salary" className="mb-2">Salary</label>
//                                                 <input type="text" placeholder="Salary" id="salary" name="salary" className="form-control" onChange={handleChange} />
//                                             </div>

//                                             <div className="col-md-6 mb-4">
//                                                 <label htmlFor="location" className="mb-2">Location<span className="req">*</span></label>
//                                                 <input type="text" placeholder="Location" id="location" name="location" className="form-control" onChange={handleChange} />
//                                             </div>
//                                         </div>

//                                         <div className="mb-4">
//                                             <label htmlFor="description" className="mb-2">Description<span className="req">*</span></label>
//                                             <textarea className="form-control" name="description" id="description" cols="5" rows="5"
//                                                 placeholder="Description" onChange={handleChange}></textarea>
//                                         </div>
//                                         <div className="mb-4">
//                                             <label htmlFor="benefits" className="mb-2">Benefits</label>
//                                             <textarea className="form-control" name="benefits" id="benefits" cols="5" rows="5"
//                                                 placeholder="Benefits" onChange={handleChange}></textarea>
//                                         </div>
//                                         <div className="mb-4">
//                                             <label htmlFor="responsibility" className="mb-2">Responsibility</label>
//                                             <textarea className="form-control" name="responsibility" id="responsibility" cols="5"
//                                                 rows="5" placeholder="Responsibility" onChange={handleChange}></textarea>
//                                         </div>
//                                         <div className="mb-4">
//                                             <label htmlFor="qualifications" className="mb-2">Qualifications</label>
//                                             <textarea className="form-control" name="qualifications" id="qualifications" cols="5"
//                                                 rows="5" placeholder="Qualifications" onChange={handleChange}></textarea>
//                                         </div>

//                                         <div className="mb-4">
//                                             <label htmlFor="keywords" className="mb-2">Keywords<span className="req">*</span></label>
//                                             <input type="text" placeholder="keywords" id="keywords" name="keywords" className="form-control" onChange={handleChange} />
//                                         </div>

//                                         <h3 className="fs-4 mb-1 mt-5 border-top pt-5">Company Details</h3>

//                                         <div className="row">
//                                             <div className="mb-4 col-md-6">
//                                                 <label htmlFor="company_name" className="mb-2">Name<span className="req">*</span></label>
//                                                 <input type="text" placeholder="Company Name" id="company_name" name="company_name" className="form-control" onChange={handleChange} />
//                                             </div>

//                                             <div className="mb-4 col-md-6">
//                                                 <label htmlFor="company_location" className="mb-2">Location</label>
//                                                 <input type="text" placeholder="Location" id="company_location" name="company_location" className="form-control" onChange={handleChange} />
//                                             </div>
//                                             <div className="mb-4 col-md-6">
//                                                 <label htmlFor="company_location" className="mb-2">Website</label>
//                                                 <input type="text" placeholder="Location" id="website" name="website" className="form-control" onChange={handleChange} />
//                                             </div>
//                                             <div className="mb-4 col-md-6">
//                                                 <label htmlFor="company_location" className="mb-2">Company Image</label>
//                                                 <input type="text" placeholder="Location" id="imgae" name="job_logo" className="form-control" onChange={handleChange} />
//                                             </div>
//                                         </div>

//                                         <div className="card-footer  p-4">
//                                             <button type="submit" className="btn btn-primary">Save Job</button>
//                                         </div>
//                                     </form>
//                                 </div>
//                             </div>

//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Footer />
//         </div>
//     )
// }
// export default Recruiter;


import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Profilee from '../images/Profilee.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../axios';

const Recruiter = () => {
    const [user, setUser] = useState();
    const [userId, setUserId] = useState(localStorage.getItem('userId'));
    const navigate = useNavigate();
    const [jobData, setJobData] = useState({
        title: '',
        category: '',
        job_nature: 'Full Time',
        vacancy: '',
        salary: '',
        location: '',
        description: '',
        benefits: '',
        responsibility: '',
        qualifications: '',
        keywords: '',
        company_name: '',
        company_location: '',
        website: '',
        job_logo: '',
        deleted: false,
        user_id:localStorage.getItem('userId')
    });

    const handleChange = (e) => {
        setJobData({ ...jobData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`http://localhost:8081/jobs/createjob/${userId}`, jobData);
            console.log('Job added successfully:', response.data);
            alert("Job added successfully!");
            // Store job ID in local storage
            localStorage.setItem('jobId', response.data.id);
        } catch (error) {
            alert("Error adding job. Please try again.");
            console.error('Error adding job:', error);
        }
    };

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        setUser(storedUserId);
    }, []);

    const logoutHandler = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/auth/logout');
            setUser(null);
            localStorage.clear('userId', 'email', 'token');
            navigate("/");
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <div>
            <Header />
            <section className="section-5 bg-2">
                <div className="container py-5">
                    <div className="row">
                        <div className="col">
                            <nav aria-label="breadcrumb" className="rounded-3 p-3 mb-4">
                                <ol className="breadcrumb mb-0">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Post a Job</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card border shadow mb-4 p-3 " style={{ width: "100%" }}>
                                <div className="s-body text-center mt-3">
                                    <div className="w-full flex justify-center items-center ">
                                        <img src={Profilee} alt="avatar" className="rounded-circle img-fluid" style={{ width: 150 }} />
                                    </div>
                                    <h5 className="mt-3 pb-0">Recruiter</h5>
                                    <p className="text-muted mb-1 fs-6">HR </p>
                                    <div className="d-flex justify-content-center mb-2">
                                        <button data-bs-toggle="modal" data-bs-target="#exampleModal" type="button" className="btn btn-primary">Change Profile Picture</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card account-nav border shadow mb-4 mb-lg-0" style={{ width: "100%" }}>
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush ">
                                        <li className="list-group-item d-flex justify-content-between p-3">
                                            <p >Account Settings</p>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <Link to="/jobdetails" >Post Job</Link>
                                        </li>
                                        <li  className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <Link to="/myjobs" >My Jobs</Link>
                                        </li >
                                        <li  className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            <Link to="/applications" >Job applicants</Link>
                                        </li >
                                        <li  className="list-group-item d-flex justify-content-between align-items-center p-3">
                                            < Link onClick={logoutHandler}>Logout</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9">
                            <div className="card border shadow mb-4" style={{ width: "100%" }}>
                                <div className="card-body card-form p-4">
                                    <h3 className="fs-4 mb-1">Job Details</h3>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="title" className="mb-2">Title<span className="req">*</span></label>
                                                <input type="text" placeholder="Job Title" id="title" name="title" className="form-control" onChange={handleChange} />
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="category" className="mb-2">Category<span className="req">*</span></label>
                                                <select name="category" id="category" className="form-control" onChange={handleChange}>
                                                    <option value="">Select a Category</option>
                                                    <option value="Engineering">Engineering</option>
                                                    <option value="Accountant">Accountant</option>
                                                    <option value="Information Technology">Information Technology</option>
                                                    <option value="Fashion designing">Fashion designing</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="job_nature" className="mb-2">Job Nature<span className="req">*</span></label>
                                                <select name="job_nature" id="job_nature" className="form-control" onChange={handleChange}>
                                                    <option value="Full Time">Full Time</option>
                                                    <option value="Part Time">Part Time</option>
                                                    <option value="Remote">Remote</option>
                                                    <option value="Freelance">Freelance</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="vacancy" className="mb-2">Vacancy<span className="req">*</span></label>
                                                <input type="number" min="1" placeholder="Vacancy" id="vacancy" name="vacancy" className="form-control" onChange={handleChange} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="salary" className="mb-2">Salary</label>
                                                <input type="text" placeholder="Salary" id="salary" name="salary" className="form-control" onChange={handleChange} />
                                            </div>

                                            <div className="col-md-6 mb-4">
                                                <label htmlFor="location" className="mb-2">Location<span className="req">*</span></label>
                                                <input type="text" placeholder="Location" id="location" name="location" className="form-control" onChange={handleChange} />
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="description" className="mb-2">Description<span className="req">*</span></label>
                                            <textarea className="form-control" name="description" id="description" cols="5" rows="5" placeholder="Description" onChange={handleChange}></textarea>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="benefits" className="mb-2">Benefits</label>
                                            <textarea className="form-control" name="benefits" id="benefits" cols="5" rows="5" placeholder="Benefits" onChange={handleChange}></textarea>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="responsibility" className="mb-2">Responsibility</label>
                                            <textarea className="form-control" name="responsibility" id="responsibility" cols="5" rows="5" placeholder="Responsibility" onChange={handleChange}></textarea>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="qualifications" className="mb-2">Qualifications</label>
                                            <textarea className="form-control" name="qualifications" id="qualifications" cols="5" rows="5" placeholder="Qualifications" onChange={handleChange}></textarea>
                                        </div>

                                        <div className="mb-4">
                                            <label htmlFor="keywords" className="mb-2">Keywords<span className="req">*</span></label>
                                            <input type="text" placeholder="Keywords" id="keywords" name="keywords" className="form-control" onChange={handleChange} />
                                        </div>

                                        <h3 className="fs-4 mb-1 mt-5 border-top pt-5">Company Details</h3>

                                        <div className="row">
                                            <div className="mb-4 col-md-6">
                                                <label htmlFor="company_name" className="mb-2">Name<span className="req">*</span></label>
                                                <input type="text" placeholder="Company Name" id="company_name" name="company_name" className="form-control" onChange={handleChange} />
                                            </div>

                                            <div className="mb-4 col-md-6">
                                                <label htmlFor="company_location" className="mb-2">Location</label>
                                                <input type="text" placeholder="Location" id="company_location" name="company_location" className="form-control" onChange={handleChange} />
                                            </div>
                                            <div className="mb-4 col-md-6">
                                                <label htmlFor="website" className="mb-2">Website</label>
                                                <input type="text" placeholder="Website" id="website" name="website" className="form-control" onChange={handleChange} />
                                            </div>
                                            <div className="mb-4 col-md-6">
                                                <label htmlFor="job_logo" className="mb-2">Company Image</label>
                                                <input type="text" placeholder="Company Image" id="job_logo" name="job_logo" className="form-control" onChange={handleChange} />
                                            </div>
                                        </div>

                                        <div className="card-footer  p-4">
                                            <button type="submit" className="btn btn-primary">Save Job</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Recruiter;

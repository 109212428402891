import React from 'react'
import maintenance from '../images/maintenance.gif'
const Maintenance = () => {
    return (
        <div className="flex justify-center items-center  flex-wrap ">
            <div className='w-full text-center font- text-3xl text-black md:font-bold '><p >Website is under Maintenance</p>
            <a href='/'> <button className="text-xl bg-lime-500 hover:bg-lime-700 text-white font-bold py-2 px-2 rounded-md" >Go to Home</button></a></div>
            <img src={maintenance} alt="maintenance" className="bg-transparent  object-fill " /> 
     </div>
    )
}
export default Maintenance; 
import React, { useState } from 'react';
import axios from '../axios'; // Ensure axios is properly configured to handle base URLs, etc.

const JobApply = ({ jobId }) => {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        applicant_name: '',
        applicant_email: '',
        applicant_mobile: '',
        applicant_experience: '0',
        resume: null, // Changed to null to handle file input
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: files[0] // Set the first file to the state
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData();
        form.append('job_id', jobId);
        form.append('applicant_name', formData.applicant_name);
        form.append('applicant_email', formData.applicant_email);
        form.append('applicant_mobile', formData.applicant_mobile);
        form.append('applicant_experience', formData.applicant_experience);
        form.append('resume', formData.resume);

        try {
            const response = await axios.post('/application/applyforjob', form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Applied successfully:', response.data);
            alert("Job application submitted successfully");
            setShowModal(false);
        } catch (error) {
            alert('Error applying for job:', error.message);
            console.error('Error applying for job:', error);
        }
    };

    return (
        <div className="w-full h-max">
            <div className="flex justify-center">
                <button
                    onClick={() => setShowModal(!showModal)}
                    className="text-white rounded-lg border bg-blue-500 hover:bg-blue-700 px-5 py-2.5 text-center text-xl font-medium shadow-sm transition-all focus:ring focus:ring-primary-200 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300"
                >
                    Apply
                </button>
            </div>
            {showModal && (
                <>
                    <div className="fixed inset-0 z-10 bg-gray-300 bg-opacity-50" onClick={() => setShowModal(false)}></div>
                    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 top-20 sm:p-0">
                        <div className="mx-auto rounded-lg bg-white shadow-xl border">
                            <div className="h-[650px] md:h-[500px] relative p-6 overflow-auto">
                                <button type="button" onClick={() => setShowModal(false)} className="absolute top-4 right-4 rounded-lg p-1 text-center font-medium text-secondary-500 transition-all hover:bg-secondary-100">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6 w-6">
                                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                    </svg>
                                </button>

                                <div className="max-w-4xl mx-auto font-sans text-[#333] p-2 pt-4">
                                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                                        <div className="grid sm:grid-cols-2 gap-y-4 gap-x-12">
                                            <div className="flex flex-wrap justify-start">
                                                <label className="text-xl">Full Name*</label>
                                                <input name="applicant_name" onChange={handleChange} type="text" className="bg-gray-100 w-full text-xl p-1 shadow-md border rounded-md outline-blue-500" placeholder="Enter name" required />
                                            </div>
                                            <div className="flex flex-wrap justify-start">
                                                <label className="text-xl">Email Id*</label>
                                                <input name="applicant_email" onChange={handleChange} type="email" className="bg-gray-100 w-full text-xl p-1 border shadow-md rounded-md outline-blue-500" placeholder="Enter email" required />
                                            </div>
                                            <div className="flex flex-wrap justify-start">
                                                <label className="text-xl">Mobile No.*</label>
                                                <input name="applicant_mobile" onChange={handleChange} type="telphone" className="bg-gray-100 border p-1 shadow-md w-full text-xl rounded-md outline-blue-500" placeholder="Enter mobile number" required />
                                            </div>
                                            <div className="flex flex-wrap justify-start">
                                                <label className="text-xl">Experience*</label>
                                                <select name="applicant_experience" onChange={handleChange} className="text-xl bg-gray-100 w-full select6 rounded-md border shadow-md outline-blue-500" required>
                                                    <option value="0">Fresher</option>
                                                    <option value="1+">1-2 years</option>
                                                    <option value="3+">3-7 years</option>
                                                    <option value="8+">8+ years</option>
                                                </select>
                                            </div>
                                            <div className="flex flex-wrap justify-start">
                                                <label className="text-xl block">Upload Resume*</label>
                                                <input name="resume" onChange={handleFileChange} type="file" className="w-full text-gray-400 font-semibold text-sm bg-white border shadow-md file:cursor-pointer cursor-pointer file:border-0 file:py-3 file:px-4 file:mr-4 file:bg-blue-500 file:hover:bg-blue-700 file:text-white file:hover:text-white rounded" accept=".pdf" required />
                                                <p className="text-xs text-gray-400 mt-2">PDF is Allowed.</p>
                                            </div>
                                        </div>
                                        <div className="w-max h-max flex justify-evenly align-center p-9">
                                            <button type="submit" className="bg-blue-500 rounded-lg border px-4 py-2 text-center text-xl font-medium text-white shadow-sm transition-all hover:bg-blue-700 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300">
                                                Apply
                                            </button>
                                            <button type="button" onClick={() => setShowModal(false)} className="rounded-lg border border-gray-300 bg-red-500 px-4 py-2 text-center text-xl font-medium text-white shadow-sm transition-all hover:bg-red-600 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default JobApply;

// import React from 'react'
// import { useState,useEffect } from 'react';
// import { auth } from '../Firebase';
import Header from '../components/Header';
// import '../App.css';
import Footer from '../components/Footer';

// export const Service = () => {
//    const [user, setUser] = useState(null);

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((currentUser) => {
//       setUser(currentUser);
//     });
//     return () => {
//       unsubscribe();
//     };
//   }, []);
//   return (
//   <div className="App">

// <Header isLoggedIn={!!user} />
//     <div className="App">
//     <h1>Apply Job</h1></div>
//     <Footer/>
//   </div>
//   )
// }

import React from 'react'
import Maintenance from '../components/Maintenance';

const Service = () => {

  return (
    <div>
      <Header />
      <div className=" w-full flex justify-center items-center flex-wrap pt-24 ">
        <div>
          <Maintenance />
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default Service
